import { Background } from '@/Components/Background'
import { Header } from '@/Components/Header'
import ModalLoading from '@/Components/ModalLoading'
import React, { useEffect, useState } from 'react'
import { AddContainer, CameraButton, CekDashContainer, DashedLine, DynamicMenuContainer, EditButton, FilterContainer, FilterFields, HeaderRow, ImagePreview, LeftSideContainer, RightSideContainer, Title, WarnText, WhiteBoxContainer, WithdrawButton } from './styled'
import { Col, Form, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { TableHeader } from '@/Models/CrudTableModel'
import { FormButton } from '@/Components/FormButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TButton } from '@/Components/TButton'
import { useToken } from '@/Components/UseToken'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService from '@/Services/api/ApiService'
import { toast } from 'react-toastify'
import ModalCapture from '@/Components/ModalCapture'
import { MalexLabelData, MalexRequest, MalexResponse } from '@/Services/api/models/malexModels'
import { GetParticipantResponse } from '@/Services/api/models/participantsModel'
import { handlePrintTicket } from '@/util/print'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { MalexConfirmModal } from '@/Components/MalexConfirmModal'
import { CameraAlt, QrCode } from '@mui/icons-material'
import ModalPicture from '@/Components/ModalPicture'
import RefreshIcon from '@mui/icons-material/Refresh';
import { MalexDeleteModal } from '@/Components/MalexDeleteModal'

interface FormValues {
    document: string;
    itemDescription: string;
    place: string; 
    itemImage: string;
    phoneNumber: string;

}

export default function MalexDash() {
    const { token } = useToken();
    const { eventID } = useParams();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [malexList, setMalexList] = useState<MalexResponse[]>()
    const [filteredParticipants, setFilteredParticipants] = useState<GetParticipantResponse[]>([])
    const [participantFilter, setParticipantFilter] = useState<string>('')
    const [participants, setParticipants] = useState<GetParticipantResponse[]>([])
    const [captureModal, setCaptureModal] = useState<boolean>(false)
    const [mobile, setMobile] = useState<boolean>(false)
    const [selectedParticipant, setSelectedParticipant] = useState<GetParticipantResponse>()
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
    const [confirmWithdraw, setConfirmWithdraw] = useState<boolean>(false)
    const [pictureModal, setPictureModal] = useState<boolean>(false)
    const [participantToWithdraw, setParticipantToWithdraw] = useState<number>()
    const [update, setUpdate] = useState<number>(0)
    const [itemToDelete, setItemToDelete] = useState<number>()
    const [itemToEdit, setItemToEdit] = useState<MalexResponse>()

    function createYupSchema() {
        const schema = {
            document: yup.string().required('O campo documento é obrigatório'),
            itemDescription: yup.string().required('O campo descrição é obrigatório'),
            place: yup.string().required('O campo localização é obrigatório'),
            phoneNumber: yup.string().required('O campo contato é obrigatório')
        };
        
        return schema
    }

    const validateSchema = yup.object().shape(createYupSchema());


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
        control,
        watch,
    } = useForm<FormValues>({
        resolver: yupResolver(validateSchema),
    });

    const handleGetParticipantMalex = async () => {
        setIsLoading(true)
        try {
            const data = await ApiService.malexGetByParticipant(Number(selectedParticipant?.id))
            setMalexList(data)
        } catch (error) {
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmitDeposit: SubmitHandler<FormValues> = async (data: FormValues) => {
        const identifier = data?.document?.trim()
        let extractedId = null
        try {
            const url = identifier?.replaceAll(';', '/')?.replaceAll(':','?')?.replaceAll('Ç',':')
            console.log(url)
            extractedId = new URL(url)?.searchParams.get('participantId')
        }
        catch (error) {
            extractedId = identifier
        }
        try{
            console.log(extractedId)
            const participantId = participants?.find(participant => participant.id === Number(extractedId))?.id ?? participants.find(participant => participant.documentNumber === data.document)?.id
            setIsLoading(true)
            const d = new Date();
            d.setHours(d.getHours() - 3);
            const requestData:MalexRequest = {
                depositDateTime: d.toISOString(),
                depositUserId: token?.user?.id,
                id: null,
                itemDescription: data.itemDescription,
                localDescription: data.place,
                itemImage: data.itemImage,
                participantId:  participantId,
                withdrawDateTime: '',
                withdrawUserId: 0,
                phoneNumber: data?.phoneNumber
            }

            if(itemToEdit) {
                requestData.id = itemToEdit.id
                requestData.participantId = itemToEdit.participantId
            }
            const response = await ApiService.malexUpInsert(requestData)
            if(response) {
                toast.success('Depósito registrado!')
            }
        } 
        catch (error) {
            console.log(error)
            if(error?.response?.data?.message === 'Cannot invoke \"java.lang.Long.longValue()\" because the return value of \"br.com.eweti.rentpass.malex.domain.Malex.getParticipantId()\" is null') {
                toast.error('Participante não encontrado')
            } else {
                toast.error(error?.message)
            }
        }
        finally {
            handleMalexGetAll()
            handleGetParticipants()
            setIsLoading(false)
            setValue('itemDescription','')
            setValue('place','')
            setValue('itemImage', '')
            if(itemToEdit) {
                setItemToEdit(undefined)
                reset()
            }
        }
    }

    const handleSubmitWithdraw = async (data: {document: string | number}) => {
        try{
            const participantId = participants?.find(participant => participant.id === Number(data.document))?.id ?? participants?.find(participant => participant.documentNumber === data.document)?.id
            const participantItems = malexList.filter(item => item.participantId === participantId && !item.withdrawDateTime)
            setIsLoading(true)
            const d = new Date();
            d.setHours(d.getHours() - 3);
            participantItems.map(async (item) => {
                const requestData:MalexRequest = {
                    depositDateTime: item.depositDateTime,
                    depositUserId: item.depositUserId,
                    id: item.id,
                    itemDescription: item.itemDescription,
                    localDescription: item.localDescription,
                    itemImage: item.itemImage,
                    participantId: item.participantId,
                    withdrawDateTime: d.toISOString(),
                    withdrawUserId: token?.user?.id,
                    phoneNumber: item?.phoneNumber
                }
                const response = await ApiService.malexUpInsert(requestData)
                if(!response) {
                    new Error('Não foi possível registrar a devolução')
                }
            })
            toast.success('Devolução registrada!')
        } catch (error: any) {
            toast.error(error)
        } finally {
            handleMalexGetAll()
            handleGetParticipants()
            setShowConfirmModal(false)
            setIsLoading(false)
        }
    }

    const handleItemWithdraw = async (item: MalexResponse) => {
        try{
            setIsLoading(true)
            const d = new Date();
            d.setHours(d.getHours() - 3);
            const requestData:MalexRequest = {
                depositDateTime: item.depositDateTime,
                depositUserId: item.depositUserId,
                id: item.id,
                itemDescription: item.itemDescription,
                localDescription: item.localDescription,
                itemImage: item.itemImage,
                participantId: item.participantId,
                withdrawDateTime: d.toISOString(),
                withdrawUserId: token?.user?.id,
                phoneNumber: item?.phoneNumber
            }
            const response = await ApiService.malexUpInsert(requestData)
            if(response) {
                toast.success('Devolução registrada!')
            }
        } catch (error: any) {
            toast.error(error.message)
        } finally {
            handleMalexGetAll()
            setIsLoading(false)
            handleGetParticipants()
        }
    }

    const handleGenerateTicket = async (p?:GetParticipantResponse) => {
        setIsLoading(true)
        const eventData = await ApiService.getEventById(Number(eventID))
        const d = new Date();
        d.setHours(d.getHours() - 3);
        const participant = p? p : selectedParticipant
        const items = malexList.filter(items => items.participantId === participant.id && !items.withdrawDateTime).map(item => item.itemDescription)
        const malexData:MalexLabelData = {
            user: token?.user.name,
            dateTime: `${d.toISOString().split('T')[0]} ${d.toISOString().split('T')[1].slice(0,8)}`,
            items: items.length > 0? items : ['Não há itens pendentes','','________________']
        }
        const print = await handlePrintTicket(eventData, participant,true,malexData)
        setIsLoading(false)
    }

    const FormatedColumns: TableHeader<any>[] = [
        {
            title: 'Nome',
            key: 'name',
        },
        {
            title: 'Sobrenome',
            key: 'lastName',
        },
        {
            title: 'Documento',
            key: 'document',
        },
        {
            title: 'Quantidade',
            key: 'quantity',
        },
        {
            title: '',
            key: 'Withdraw'
        },
        {
            title: '',
            key: 'protocol'
        }
    ];

    const handleGetParticipants = async () => {
        try {
            const participants = await ApiService.getAllParticipantsByEvent(Number(eventID))
            setParticipants(participants)
        } catch (error) {
            console.log(error)
        }
    }

    const handleMalexGetAll = async () => {
        setIsLoading(true)
        try {
            const data = await ApiService.malexGetByEvent(Number(eventID))
            setMalexList(data)
        } catch (error) {
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    const checkModuleAvailability = async () => {
        const event = await ApiService.getEventById(Number(eventID))
        if(!event?.malexControl) {
            navigate('/')
        }
    }

    const handleItemDelete = async (id: number) => {
        setIsLoading(true)
        try {
            const reponse = await ApiService.malexDelete(id)
        } catch (e: any) {
            toast.error('Não foi possível excluir')
        }
        finally {
            setIsLoading(false)
            toast.success('Registro excluído com sucesso')
            handleMalexGetAll()
            setItemToDelete(undefined)
        }
    }

    useEffect(() => {
        if(itemToEdit) {
            setValue('itemDescription', itemToEdit?.itemDescription)
            setValue('itemImage', itemToEdit?.itemImage)
            setValue('phoneNumber', itemToEdit?.phoneNumber)
            setValue('place', itemToEdit?.localDescription)
            setValue('document', itemToEdit?.participantId?.toString())
        }
    },[itemToEdit])

    useEffect(() => {
        handleGetParticipants()
        checkModuleAvailability()
    },[update])

    useEffect(() => {
        if(selectedParticipant) {
            handleGetParticipantMalex()
        }
        else {
            handleMalexGetAll()
        }
    },[selectedParticipant, update])

    useEffect(() => {
        if(malexList) {
            const localFilteredParticipants = participants.filter(participant => {return malexList.some(item => item.participantId === participant.id)})
            if(participantFilter === '') {
                const sortedLocalFilteredParticipants = localFilteredParticipants?.sort((a,b) => new Date(malexList?.filter((p) => p?.participantId === b?.id)?.slice(-1)?.[0]?.depositDateTime)?.getTime() - new Date(malexList?.filter((p) => p?.participantId === a?.id)?.slice(-1)?.[0]?.depositDateTime)?.getTime())
                setFilteredParticipants(sortedLocalFilteredParticipants)
            }
            else {
                setFilteredParticipants(localFilteredParticipants.filter(participant => (participant.name+participant.lastName).toString().toLowerCase().includes(participantFilter.toLowerCase()) || participant.documentNumber.toLowerCase().includes(participantFilter.toLowerCase())))
            }
        }
    },[participants, participantFilter, malexList])

    useEffect(() => {
        if(pictureModal && watch('itemImage')) {
            setPictureModal(false)
        }
    },[watch('itemImage')])

    useEffect(() => {
        setCaptureModal(false)
    },[watch('document')])

    useEffect(() => {
        if(window.innerWidth < 1200){
            setMobile(true)
        }
    },[])

    useEffect(() => {
        const input = watch('document')
        let participant = null
        try {
            console.log(input)
            const participantId = new URL(input?.replaceAll(';', '/')?.replaceAll(':','?')?.replaceAll('Ç',':'))?.searchParams?.get('participantId')
            participant = participants?.find(p => p?.id === Number(participantId))
            
        } catch (error:any) {
            if(input && input?.length === 11) {
                participant = participants?.find(p => p?.documentNumber === input)
            }
        } finally {
            if(participant) {
                setValue('phoneNumber', participant?.cellPhoneNumber)
            }
        }
    },[watch('document')])

  return (
    <Background>
        <MalexConfirmModal show={showConfirmModal} onClose={() => setShowConfirmModal(false)} onConfirm={() => handleSubmitWithdraw({ document: participantToWithdraw })}/>
        <MalexDeleteModal show={itemToDelete? true : false} onClose={() => setItemToDelete(undefined)} onConfirm={() => handleItemDelete(itemToDelete)}/>
        <ModalLoading isActive={isLoading} />
        <ModalCapture isActive={captureModal} getResult={(value:string) => setValue('document', value)} onClose={() => setCaptureModal(false)}/>
        <ModalPicture isActive={pictureModal} getResult={(value:string) => setValue('itemImage', value)} onClose={() => setPictureModal(false)}/>
        <CekDashContainer>
            <Header pageTitle='Malex' />
            <HeaderRow>
                <FormButton onClick={() => navigate(`/evento/${eventID}`)}>
                    Voltar para a Dashboard
                </FormButton>
                <div onClick={() => setUpdate(update => update+1)}>
                    <RefreshIcon />
                </div>
            </HeaderRow>
            <WhiteBoxContainer>
                <LeftSideContainer>
                    <Title>Registrar Depósito</Title>
                    <AddContainer>
                        <Form onSubmit={handleSubmit(handleSubmitDeposit)}>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label>Documento</Form.Label>
                                <Col sm={10}>
                                    <QrCode onClick={() => setCaptureModal(true)} />
                                    <Form.Control disabled={itemToEdit? true : false} autoFocus {...register('document')} type='text' onChange={(e) => setValue('document',e.target.value)}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label>Descrição do Item</Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        disabled={itemToEdit? true : false}
                                        type='text'
                                        {...register('itemDescription')}
                                    />
                                    {errors.itemDescription?.message && (
                                        <WarnText> {errors.itemDescription.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label>Localização</Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        type='text'
                                        {...register('place')}
                                    />
                                    {errors.place?.message && (
                                        <WarnText> {errors.place.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Form.Label>Contato</Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        disabled={itemToEdit? true : false}
                                        type='text'
                                        {...register('phoneNumber')}
                                    />
                                    {errors.phoneNumber?.message && (
                                        <WarnText> {errors.phoneNumber.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className='mb-3'>
                                <Col sm={10}>
                                    {watch('itemImage')?
                                        <ImagePreview src={watch('itemImage')} />
                                    :
                                        <CameraButton onClick={() => !itemToEdit && setPictureModal(true)}>
                                            Tirar Foto
                                            <CameraAlt/>
                                        </CameraButton>
                                    } 
                                </Col>
                            </Form.Group>
                            <TButton type='submit'>
                                Registrar
                            </TButton>
                        </Form>
                    </AddContainer>
                </LeftSideContainer>
                <DashedLine />
                <RightSideContainer>
                <DynamicMenuContainer>
                        <FilterContainer>
                            {selectedParticipant? 
                                <FilterFields>
                                    <FormButton onClick={() => setSelectedParticipant(null)}>Voltar à lista</FormButton>
                                    <FormButton onClick={() => handleGenerateTicket()}>Gerar Protocolo</FormButton>
                                </FilterFields>
                            :
                                <FilterFields>
                                    <Form.Control
                                        type='text'
                                        placeholder='Filtrar participantes'
                                        onChange={(e) => setParticipantFilter(e.target.value)}
                                        className='addField'
                                    />
                                </FilterFields>
                            }
                        </FilterContainer>
                    </DynamicMenuContainer>
                    <div className='tabela'>
                        {selectedParticipant?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Imagem</th>
                                        <th>Item</th>
                                        <th>Localização</th>
                                        <th>Contato</th>
                                        <th>Horário Depósito</th>
                                        <th>Horário Devolução</th>
                                        <th></th>
                                        {token?.user?.userGroup ===  'ADMIN' &&
                                            <th></th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {malexList.filter(item => item.participantId === selectedParticipant.id).map(item =>
                                        <tr>
                                            <td>
                                                <ImagePreview src={item.itemImage}/>
                                            </td>
                                            <td>{item.itemDescription}</td>
                                            <td>{item.localDescription}</td>
                                            <td>{item?.phoneNumber}</td>
                                            <td>{item.depositDateTime}</td>
                                            <td>{item.withdrawDateTime? 
                                                    item.withdrawDateTime 
                                                : 
                                                    <WithdrawButton
                                                        onClick={() => handleItemWithdraw(item)}
                                                    >
                                                        Devolver
                                                    </WithdrawButton>}
                                            </td>
                                            <td>
                                                <EditButton
                                                    onClick={() => setItemToEdit(item)}
                                                >
                                                    Editar
                                                </EditButton>
                                            </td>
                                            {token?.user?.userGroup ===  'ADMIN' &&
                                                <td>
                                                    <WithdrawButton
                                                        onClick={() => setItemToDelete(item.id)}
                                                    >
                                                        Excluir registro
                                                    </WithdrawButton>
                                                </td>
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        :
                            <Table hover id='participantsTable'>
                                <thead>
                                    <tr>
                                        {FormatedColumns.map((column, index) => 
                                            <th key={index}>
                                                {column.title}
                                            </th>
                                        )}
                                    </tr>
                                </thead> 
                                <tbody>
                                    {filteredParticipants?.map((participant) => 
                                        <tr onDoubleClick={() => setSelectedParticipant(participant)}>
                                            <td>{participant.name}</td>
                                            <td>{participant.lastName}</td>
                                            <td>{participant.documentNumber}</td>
                                            <td>{`Total: ${malexList.filter(item => item.participantId === participant.id).length} | Pendentes: ${malexList.filter(item => item.participantId === participant.id && !item.withdrawDateTime).length}`}</td>
                                            <td>
                                                {malexList.filter(item => item.participantId === participant.id && !item.withdrawDateTime).length > 0? 
                                                    <WithdrawButton
                                                        onClick={() => {setShowConfirmModal(true); setParticipantToWithdraw(participant?.id)}}
                                                    >
                                                        Devolver tudo
                                                    </WithdrawButton>
                                                :
                                                    'Nenhum item pendente'
                                                }
                                            </td>
                                            <td>
                                                <FormButton onClick={() => handleGenerateTicket(participant)}>Gerar Protocolo</FormButton>
                                            </td>
                                        </tr>
                                    )}
                                </tbody> 
                            </Table>
                        }
                    </div>
                </RightSideContainer>
            </WhiteBoxContainer>
        </CekDashContainer>
    </Background>
  )
}