import { Background } from '@/Components/Background'
import { Header } from '@/Components/Header'
import React, { useEffect, useState } from 'react'
import { AddContainer, AttractionTitle, BlueCheckInArrow, CekDashContainer, Chart, ChartsContainer, CheckinRow, DynamicMenuContainer, FilterContainer, FilterFields, Grow, HeaderRow, LeftSideContainer, MetricsContainer, MetricsTitle, RedCheckInArrow, RightSideContainer, Title, WhiteBoxContainer } from './styled'
import { Form, Table, Tooltip } from 'react-bootstrap'
import { TButton } from '@/Components/TButton'
import { Cell, Legend, Pie, PieChart } from 'recharts'
import { useForm } from 'react-hook-form'
import { FormButton } from '@/Components/FormButton'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService from '@/Services/api/ApiService'
import { Attraction, AttractionParticipantCheckIn, AttractionSummary } from '@/Services/api/models/attractionsModels'
import ModalLoading from '@/Components/ModalLoading'
import { toast } from 'react-toastify'
import { GetParticipantResponse } from '@/Services/api/models/participantsModel'
import { ExportRoomData, Room, RoomParticipant, RoomParticipantCheckIn } from '@/Services/api/models/ControleDeAcessoModels'
import ControleDeAcessoService, { websocketURL } from '@/Services/controle-de-acesso/ControleDeAcessoService'
import { formatDateToString, formatDjangoDate } from '@/util/FormatDate'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { ExportRoomToExcel } from '@/util/SaveFromExcel'
import { SignInResponse } from '@/Services/api/models/signInModel'


export default function ControleDeAcesso() {
    const navigate = useNavigate()
    const { eventID, roomID } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [participantFilter, setParticipantFilter] = useState<string>()
    const [room, setRoom] = useState<Room>()
    const [roomParticipantList, setRoomParticipantList] = useState<RoomParticipant[]>()
    const [filteredRoomParticipantList, setFilteredRoomParticipantList] = useState<RoomParticipant[]>()
    const [checkIn, setCheckIn] = useState<RoomParticipantCheckIn[]>()
    const [userInfo, setUserInfo] = useState<SignInResponse>()
    const [participants, setParticipants] = useState<GetParticipantResponse[]>()

    const chartData = [
        {name: 'Check-ins', value: roomParticipantList?.filter((participant) => checkIn?.map((p) => p.participant.rentpass_participant)?.includes(participant.rentpass_participant))?.length},
        {name: 'Participantes na Sala', value: roomParticipantList?.length - roomParticipantList?.filter((participant) => checkIn?.map((p) => p.participant.rentpass_participant)?.includes(participant.rentpass_participant))?.length},
    ]


    const COLORS = [
        '#3366cc',
        '#dc3912',
        '#ff9900',
        '#109618',
        '#990099',
        '#0099c6',
        '#dd4477',
        '#66aa00',
        '#b82e2e',
        '#316395',
        '#3366cc',
        '#994499',
        '#22aa99',
        '#aaaa11',
        '#6633cc',
        '#e67300',
        '#8b0707',
        '#651067',
        '#329262',
        '#5574a6',
        '#3b3eac',
        '#b77322',
        '#16d620',
        '#b91383',
        '#f4359e',
        '#9c5935',
        '#a9c413',
        '#2a778d',
        '#668d1c',
        '#bea413',
        '#0c5922',
        '#743411',
    ];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill='white'
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline='central'
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const handleGetParticipants = async () => {
        setIsLoading(true)
        try {
          const data = await ApiService.getAllParticipantsByEvent(Number(eventID))
          setParticipants(data)
        }
        catch (e: any) {
          toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
      }

    const handleGetUserInfo = async () => {
        setIsLoading(true)
        try{
            const userInfo = localStorage.getItem('token')
            const jsonUserInfo = JSON.parse(userInfo)
            setUserInfo(jsonUserInfo)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const getRoom = async () => {
        setIsLoading(true)
        try {
            const data = await ControleDeAcessoService.getRoomById(Number(roomID))
            setRoom(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const handleGetRoomParticipantList = async () => {
        setIsLoading(true)
        try {
            const data = await ControleDeAcessoService.getAllowedParticipantsByRoom(Number(roomID))
            setRoomParticipantList(data)
            setFilteredRoomParticipantList(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }


    const handleFilterParticipant = () => {
        if (participantFilter === '' || !participantFilter) {
            setFilteredRoomParticipantList(roomParticipantList)
        }
        else {
            const filteredParticipants = roomParticipantList.filter((participant) => 
                participant?.rentpass_participant?.toString() === participantFilter ||
                participants?.find(p => p.id === participant?.rentpass_participant)?.name?.toLowerCase()?.includes(participantFilter?.toLowerCase().replaceAll(' ','')) ||
                participants?.find(p => p.id === participant?.rentpass_participant)?.lastName?.toLowerCase()?.includes(participantFilter?.toLowerCase().replaceAll(' ','')) ||
                participants?.find(p => p.id === participant?.rentpass_participant)?.documentNumber?.includes(participantFilter)
            )
            setFilteredRoomParticipantList(filteredParticipants)
        }
    }

    const getCheckIn = async () => {
        setIsLoading(true)
        try {
            const data = await ControleDeAcessoService.getCheckInsByRoom(Number(roomID))
            setCheckIn(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const handleExportToExcel = async () => {
        const exportData:ExportRoomData[] = checkIn.map((entry) => {
            return {
                id: participants?.find(participant => participant.id === entry.participant.rentpass_participant)?.id,
                name: participants?.find(participant => participant.id === entry.participant.rentpass_participant)?.name,
                lastName: participants?.find(participant => participant.id === entry.participant.rentpass_participant)?.lastName,
                documentNumber: participants?.find(participant => participant.id === entry.participant.rentpass_participant)?.documentNumber,
                checkInDate: participants?.find(participant => participant.id === entry.participant.rentpass_participant)?.checkinDate,
                CheckIn: entry.timestamp
            }
        })
        ExportRoomToExcel(room?.room_name, exportData)
    }

    useEffect(() => {
        if(eventID){
            const ws = new WebSocket(`${websocketURL}/room/?room=${roomID}`)
            ws.onmessage = (e) => {
                console.log(e.data)
                const data = JSON.parse(e.data)
                if(Object.keys(data)?.includes('check-ins')) {
                    const checkIns = data['check-ins']?.filter((c: { room: number }) => Number(c.room) === Number(roomID))
                    setCheckIn(checkIn => [...checkIn,...checkIns])
                }
            }
            return () => {
                ws.close()
            }
        }
    },[eventID])

    useEffect(() => {
        handleGetUserInfo()
        handleGetParticipants()
        getRoom()
        handleGetRoomParticipantList()
        getCheckIn()
    },[])

    useEffect(() => {
        handleFilterParticipant()
    },[participantFilter, roomParticipantList])

  return (
    <Background>
        <ModalLoading isActive={isLoading} />
        <CekDashContainer>
            <Header pageTitle='Controle de Acesso' />
            <HeaderRow>
                <FormButton onClick={() => navigate(-1)}>
                    Voltar
                </FormButton>
            </HeaderRow>
            <AttractionTitle>
                <span>Sala: </span>
                {`${room?.room_name}`}
            </AttractionTitle>
            <WhiteBoxContainer>
                <LeftSideContainer>
                    <AddContainer>
                        {userInfo?.user?.userGroup === 'ADMIN' &&
                        <Grow>
                            <FormButton onClick={handleExportToExcel}>
                                Exportar Excel
                            </FormButton>
                        </Grow>
                            }
                        <Grow>
                            <FormButton onClick={() => navigate(`/modulos/controle-de-acesso/check-in/${eventID}/${roomID}`)}>
                                Check-in/Check-out
                            </FormButton>
                        </Grow>
                    </AddContainer>
                    <DynamicMenuContainer>
                        <FilterFields>
                            <Form.Control
                                type='text'
                                autoFocus
                                placeholder='Filtrar participante por Nome, Documento ou ID'
                                onChange={(e) => setParticipantFilter(e.target.value)}
                                className='addField'
                            />
                        </FilterFields>
                    </DynamicMenuContainer>
                    <div className='tabela'>
                        <Table hover id='participantsTable'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nome</th>
                                    <th>Sobrenome</th>
                                    <th>Check-in</th>
                                </tr>
                                </thead> 
                                <tbody>
                                    {filteredRoomParticipantList?.map((participant) => 
                                        <tr>
                                            <td></td>
                                            <td>{participants?.find((p => p.id === participant.rentpass_participant))?.name}</td>
                                            <td>{participants?.find((p => p.id === participant.rentpass_participant))?.lastName}</td>
                                            <td>{checkIn?.filter((p => p.participant?.rentpass_participant === participant.rentpass_participant))?.map((checkin, index) => 
                                                <CheckinRow>
                                                    {formatDjangoDate(checkin.timestamp)}
                                                    {(index+1)%2 === 0? <RedCheckInArrow><ArrowDownward /></RedCheckInArrow> : <BlueCheckInArrow><ArrowUpward /></BlueCheckInArrow>}
                                                </CheckinRow>
                                            )}</td>
                                        </tr>
                                    )}
                                </tbody> 
                        </Table>
                    </div>
                </LeftSideContainer>
                <RightSideContainer>
                    <ChartsContainer>
                        <Chart>
                            <Title> Participantes </Title>
                            <PieChart width={300} height={300}>
                                <Pie
                                    data={chartData}
                                    cx='50%'
                                    cy='50%'
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill='#8884d8'
                                    dataKey='value'
                                >
                                    {chartData.map((_, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend layout='vertical' verticalAlign='bottom' />
                            </PieChart>
                        </Chart>
                        <MetricsContainer>
                            <MetricsTitle> Lugares na sala: {room?.room_availability} </MetricsTitle>
                            <MetricsTitle> Total de Participantes Esperados: {roomParticipantList?.length} </MetricsTitle>
                            <MetricsTitle> Total de Participantes com Check-in: {roomParticipantList?.filter((participant) => checkIn?.map((p) => p.participant.rentpass_participant)?.includes(participant.rentpass_participant))?.length} </MetricsTitle>
                        </MetricsContainer>
                    </ChartsContainer>
                </RightSideContainer>
            </WhiteBoxContainer>
        </CekDashContainer>
    </Background>
  )
}
