import { Background } from '@/Components/Background'
import { Header } from '@/Components/Header'
import ApiService from '@/Services/api/ApiService';
import { EventResponse } from '@/Services/api/models/eventModel';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Menu, MenuItem, MenuTitle, RowContainer, WhiteBoxContainer } from './styled';
import { EventReports } from '../Relatórios';
import { BarChartRounded, Casino, ConfirmationNumber, Key, Label, Luggage, MonetizationOn, People, Phone, QrCodeScanner, QuestionAnswer, ShoppingBag, Smartphone } from '@mui/icons-material';
import { GetParticipants } from '@/Pages/Participants/Consultar';
import CekDash from '@/Pages/Modules/Cek/Consultar';
import ModalAppConfig from '@/Components/ModalAppConfig';
import Enquetes from '@/Pages/Modules/Enquetes/Consultar';
import AppConfig from '@/Components/AppConfig';
import { useToken } from '@/Components/UseToken';
import Drawing from '@/Components/Drawing';
import Pedidos from '../Pedidos';
import Financeiro from '../Financeiro';

interface Page {
    name: string;
    category: string;
    component: any;
    icon: any;
    type: 'redirect' | 'render';
    path: string;
}

export default function Dashboard() {
    const { token } = useToken()
    const navigate = useNavigate()
    const { eventId } = useParams();
    const [eventInfo, setEventInfo] = useState<EventResponse>()
    const [selectedPage, setSelectedPage] = useState<Page>()
    const [pages, setPages] = useState<Page[]>()

    const populateDefaultPages = () => {
        const p:Page[] = [
            {   
                name: 'Lista de Participantes',
                category: 'Credenciamento',
                component: null,
                type: 'redirect',
                icon: <People />,
                path: `/participantes/consultar/${eventId}`
            },
            {   
                name: 'Sorteio',
                category: 'Módulos',
                component: <Drawing  eventId={eventId} />,
                type: 'render',
                icon: <Casino />,
                path: ``
            }
        ]
        if(token.user.userGroup !== 'RECEPTION') {
            p?.push(
                {
                    name: 'Relatórios do Evento',
                    category: 'Gerencial',
                    component: <EventReports />,
                    icon: <BarChartRounded />,
                    type: 'render',
                    path: ``
                },
                {
                    name: 'Pedidos',
                    category: 'Gerencial',
                    component: <Pedidos eventId={eventId} />,
                    icon: <ConfirmationNumber />,
                    type: 'render',
                    path: ``
                },
                {
                    name: 'Financeiro',
                    category: 'Gerencial',
                    component: <Financeiro eventId={eventId} />,
                    icon: <MonetizationOn />,
                    type: 'render',
                    path: ``
                }
            )
        }
        setPages(p)
    }



    const getEventInfo = async () => {
        const response = await ApiService.getEventById(Number(eventId))
        setEventInfo(response)
        let pagesAdd = []
        if(response?.accessControl && token?.user?.userGroup !== 'RECEPTION') {
            pagesAdd?.push({
                name: 'Controle de Acesso',
                category: 'Módulos',
                component: null,
                type: 'redirect',
                icon: <Key />,
                path: `/modulos/controle-de-acesso/salas/${eventId}`
            })
        }
        if(response?.kitQuantityLimit > 0) {
            pagesAdd?.push({
                name: 'Controle de Brindes',
                category: 'Módulos',
                component: <CekDash eventID={eventId}/>,
                type: 'render',
                icon: <ShoppingBag />,
                path: ``
            })
        }
        if(response?.malexControl) {
            pagesAdd?.push({
                name: 'Malex',
                category: 'Módulos',
                component: null,
                type: 'redirect',
                icon: <Luggage/>,
                path: `/modulos/malex/consultar/${eventId}`
            })
        }
        if(response?.attractionControl && token?.user?.userGroup !== 'RECEPTION') {
            pagesAdd?.push({
                name: 'Coleta de Leads',
                category: 'Módulos',
                component: null,
                type: 'redirect',
                icon: <QrCodeScanner/>,
                path: `/modulos/cess/atracoes/${eventId}`
            })
        }
        if(response?.surveyControl && token?.user?.userGroup !== 'RECEPTION') {
            pagesAdd?.push({
                name: 'Enquetes',
                category: 'Módulos',
                component: <Enquetes eventID={eventId}/>,
                type: 'render',
                icon: <QuestionAnswer />,
                path: ``
            })
        }
        setPages(p => [...p, ...pagesAdd])
    }

    const loadAppConfig = () => {
        setPages(p => [
            ...p,
            {   
                name: 'Abrir no App',
                category: 'App',
                component: <AppConfig event={eventInfo}/>,
                type: 'render',
                icon: <Smartphone />,
                path: ``
            }
        ])
    }

    useEffect(() => {
        if(eventId) {
            getEventInfo()
            populateDefaultPages()
        }
    },[eventId])

    useEffect(() => {
        setSelectedPage(pages?.find(p => p?.name === (token.user.userGroup === 'RECEPTION'? 'Abrir no App' : 'Relatórios do Evento')))
    },[pages])

    useEffect(() => {
        if(eventInfo) {
            loadAppConfig()
        }
    },[eventInfo])


  return (
    <Background>
        <Header pageTitle={eventInfo?.description} />
        <RowContainer>
            <Menu>
                {token?.user?.userGroup !== 'RECEPTION' &&
                    <MenuTitle>
                        Gerencial
                    </MenuTitle>
                }
                {pages?.filter(p => p?.category === 'Gerencial').map((p) => 
                    <MenuItem disabled={false} selected={selectedPage === p} onClick={() => p?.type === 'render'? setSelectedPage(p) : navigate(p?.path)}>
                        {p?.icon}
                        {p?.name}
                    </MenuItem>
                )}
                <MenuTitle>
                    Credenciamento
                </MenuTitle>
                {pages?.filter(p => p?.category === 'Credenciamento').map((p) => 
                    <MenuItem disabled={false} selected={selectedPage === p} onClick={() => p?.type === 'render'? setSelectedPage(p) : navigate(p?.path)}>
                        {p?.icon}
                        {p?.name}
                    </MenuItem>
                )}
                <MenuTitle>
                    Módulos
                </MenuTitle>
                {pages?.filter(p => p?.category === 'Módulos').map((p) => 
                    <MenuItem disabled={false} selected={selectedPage === p} onClick={() => p?.type === 'render'? setSelectedPage(p) : navigate(p?.path)}>
                        {p?.icon}
                        {p?.name}
                    </MenuItem>
                )}
                <MenuTitle>
                    App
                </MenuTitle>
                {pages?.filter(p => p?.category === 'App').map((p) => 
                    <MenuItem disabled={false} selected={selectedPage === p} onClick={() => p?.type === 'render'? setSelectedPage(p) : navigate(p?.path)}>
                        {p?.icon}
                        {p?.name}
                    </MenuItem>
                )}
            </Menu>
            <WhiteBoxContainer>
                {selectedPage?.component}
            </WhiteBoxContainer>
        </RowContainer>
    </Background>
  )
}
