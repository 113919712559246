import { Background } from '@/Components/Background'
import React, { useEffect, useState } from 'react'
import { FilterContainer, FilterFields, GetQuestionsContainer, Grow, Row, SmallerColumn, WhiteBoxContainer, Title } from './styled'
import { Header } from '@/Components/Header'
import { EventTitle } from '@/Pages/Participants/Consultar/styled'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate, useParams } from 'react-router-dom'
import { EventResponse } from '@/Services/api/models/eventModel'
import ModalLoading from '@/Components/ModalLoading'
import ApiService from '@/Services/api/ApiService'
import { toast } from 'react-toastify'
import { FormButton } from '@/Components/FormButton'
import { Form, Table } from 'react-bootstrap'
import { useToken } from '@/Components/UseToken'
import { QuestionResponse, ResponseResponse } from '@/Services/api/models/surveyModels'
import { GetParticipantResponse } from '@/Services/api/models/participantsModel'

interface Props {
  eventID: string;
}

export default function Enquetes(props: Props) {
  const navigate = useNavigate();
  const eventID = props?.eventID
  const [currentEvent, setCurrentEvent] = useState<EventResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState<number>(0)
  const [participantFlter, setParticipantFilter] = useState<string>()
  const [questions, setQuestions] = useState<QuestionResponse[]>()
  const { token } = useToken();
  const [columns, setColumns] = useState<string[]>()
  const [participants, setParticipants] = useState<GetParticipantResponse[]>();
  const [answers, setAnwers] = useState<ResponseResponse[]>()

  const handleGetEventInfos = async () => {
    try {
        setIsLoading(true);
        const event = await ApiService.getEventById(Number(eventID));
        setCurrentEvent(event);
    } catch (error: any) {
        toast.error(error.message);
    } finally {
        setIsLoading(false);
    }
  };

  const handleGetParticipants = async () => {
    try {
        setIsLoading(true);
        const participants = await ApiService.getAllParticipantsByEvent(Number(eventID));
        setParticipants(participants);
        console.log(participants)
    } catch (error: any) {
        toast.error(error.message);
    } finally {
        setIsLoading(false);
    }
  };

  const handleGetAllResponseByQuestionId = async () => {
    setIsLoading(true);

    try {
        const answerPromises = questions?.map(async (question) => {
            try {
                const answers = await ApiService.getAllResponsesByQuestionId(Number(question.id));
                return answers;
            } catch (error) {
                toast.error(error.message);
                return [];
            }
        });

        const answersArrays = await Promise.all(answerPromises);
        const localAnswers = answersArrays.flat();
        setAnwers(localAnswers);
    } catch (error) {
        toast.error(error.message);
    } finally {
        setIsLoading(false);
    }
  };

  const handleExportToExcel = () => {

  }

  const handleSelectAllParticipants = () => {

  }

  const handleQuestionsByEvent = async () => {
    const response = await ApiService.getQuestionsByEventId(Number(eventID))
    setQuestions(response)
  }


  useEffect(() => {
    handleGetEventInfos()
    handleQuestionsByEvent()
    handleGetParticipants()
  },[])

  useEffect(() => {
    if(questions) {
      handleGetAllResponseByQuestionId()
      let columns = ['Nome', 'Sobrenome']
      questions.map((question) => {
        columns.push(question.description)
      })
      setColumns(columns)
    }
  },[questions])

  return (
          <WhiteBoxContainer>
            <Title>
              Enquetes
            </Title>
            <ModalLoading isActive={isLoading} />
            <Grow>
              {token?.user?.userGroup === 'ADMIN' &&
                <FormButton
                  onClick={() => navigate(`/modulos/enquetes/configuracao/${eventID}`)}
                >
                  Configurar
                </FormButton>
              }
              <FormButton
                onClick={() => handleExportToExcel()}
              >
                Exportar Excel
              </FormButton>
            </Grow>
            <div className='body'>
              <FilterContainer>
              <FilterFields>
                <Form.Control
                  type='text'
                  placeholder='Filtrar participantes'
                  onChange={(e) => {
                    setParticipantFilter(e.target.value);
                  }}
                />
                </FilterFields>
                </FilterContainer>
            </div>
            <div className='tabela'>
              <Table hover id='participantsTable'>
                <thead>
                  <tr>
                    <th>
                      <Form.Check
                        type={'checkbox'}
                        onChange={handleSelectAllParticipants}
                      />
                    </th>
                    {columns?.map((column, index) => 
                      <th key={index}>
                        {column}
                      </th>
                    )}
                  </tr>                                    
                </thead>
                <tbody>
                  {participants?.map((participant, index) =>
                    <tr key={index}>
                      <SmallerColumn>
                        <Form.Check
                          type={'checkbox'}
                          onChange={() => {}}
                        />
                      </SmallerColumn>
                      <td>{participant.name}</td>
                      <td>{participant.lastName}</td>
                      {questions?.map((question) =>
                        <td>{answers?.find((answer) => answer.participantId === participant.id && answer.questionId === question.id)?.description}</td>
                      )}
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </WhiteBoxContainer>
  )
}
