import { MenuOption } from '@/Pages/Events/ConfigureLabel/styled';
import { useDraggable } from '@dnd-kit/core';
import React, { ReactElement } from 'react'

interface Props {
    name: string;
    icon: ReactElement;
    value: string;
}

export default function DraggableMenuItem(props:Props) {
    const id = props.value
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id,
    });

    const style = {
        transform: transform
            ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
            : undefined,
    };

    return (
        <MenuOption
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            style={style}
        >
            {props.icon}
            {props.name}
        </MenuOption>
    );
}

