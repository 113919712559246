import React, { useEffect, useState } from 'react'
import { Background, FilterContainer, FilterFields, SmallerColumn, StatusButton, Title } from './styled'
import { Dropdown, DropdownButton, Form, Table } from 'react-bootstrap'
import RsvpService from '@/Services/rsvp/RsvpService'
import { OrderResponse, OrderStatus, PaymentMethod } from '@/Services/api/models/rsvpModels';
import { formatDjangoDate } from '@/util/FormatDate';
import ModalLoading from '@/Components/ModalLoading';
import { toast } from 'react-toastify';

interface Props {
    eventId: string;
}

export default function Pedidos(props:Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [orders, setOrders] = useState<OrderResponse[]>()
    const [filteredOrders, setFilteredOrders] = useState<OrderResponse[]>()
    const [orderFilter, setOrderFilter] = useState<string>()

    const getOrders = async () => {
        setIsLoading(true)
        const data = await RsvpService.getOrdersByEvent(props.eventId)
        setOrders(data)
        setFilteredOrders(data)
        setIsLoading(false)
    }

    const filterOrder = () => {
        setFilteredOrders(() => {
            const result = []
            result.push(...orders?.filter(o => o?.payer_name?.includes(orderFilter)))
            result.push(...orders?.filter(o => o?.payer_email?.includes(orderFilter)))
            result.push(...orders?.filter(o => o?.payer_cpf_cnpj?.includes(orderFilter)))
            return result
        })
    }

    const resendTickets = async (o: OrderResponse) => {
        if(o?.status === 'PAID') {
            const response = await RsvpService.sendConfirmationEmail(o?.id)
            toast.success('Ingressos enviados')
        } else {
            toast.error('Não é possível enviar ingressos para pedidos não pagos')
        }
    }

    const handleGeneratePaymentLink = async(o: OrderResponse) => {
        if(!(o?.status === 'PAID')) {
            const link = navigator.clipboard.writeText(`https://aptf.rentpass.com.br/pagamento/${o?.id}?token=${btoa(o?.payer_cpf_cnpj)}`)
            toast.success('Link copiado!')
        }
    }

    const actionOptions = [
        {
            title: 'Reenviar ingressos',
            onClick: (o) => resendTickets(o)
        },
        {
            title: 'Gerar link de pagamento',
            onClick: (o) => handleGeneratePaymentLink(o)
        }
    ]

    useEffect(() => {
        getOrders()
    },[])

    useEffect(() => {
        if(orderFilter && orderFilter !== '') {
            filterOrder()
        }
    },[orderFilter])

  return (
    <Background>
        <ModalLoading isActive={isLoading}/>
        <Title>
            Pedidos
        </Title>
        <div className='body'>
            <FilterContainer>
                <Form.Label column>{`${
                    filteredOrders?.length ?? 0} pedidos`}</Form.Label>
                <FilterFields>
                    <Form.Control
                        type='text'
                        placeholder='Filtrar pedidos'
                        onChange={(e) => {
                            setOrderFilter(e.target.value);
                        }}
                    />
                </FilterFields>
            </FilterContainer>
        </div>
        <div className={'tabela'}>
            <Table hover id='orderTable'>
                <thead>
                    <tr>
                        <SmallerColumn></SmallerColumn>
                        <th>
                            ID
                        </th>
                        <th>
                            Pagador
                        </th>
                        <th>
                            E-mail
                        </th>
                        <th>
                            CPF
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            Valor
                        </th>
                        <th>
                            Pagamento
                        </th>
                        <th>
                            Parcelas
                        </th>
                        <th>
                            Criado
                        </th>
                        <th>
                            Link/Lote
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredOrders?.map((o) => 
                        <tr>
                            <SmallerColumn>
                                {' '}
                                <DropdownButton
                                    variant='warning'
                                    id={`dropdown-variants-Warning`}
                                    title={'Ação'}
                                    color={'white'}
                                    className='teste'
                                    size='sm'
                                >
                                    {actionOptions.map((action) => {
                                        return (
                                            <Dropdown.Item
                                                key={action.title}
                                                onClick={() =>
                                                    action.onClick(
                                                        o,
                                                    )
                                                }
                                            >
                                                {' '}
                                                {action.title}{' '}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </DropdownButton>{' '}
                            </SmallerColumn>
                            <td>
                                {o?.id}
                            </td>
                            <td>
                                {o?.payer_name}
                            </td>
                            <td>
                                {o?.payer_email}
                            </td>
                            <td>
                                {o?.payer_cpf_cnpj}
                            </td>
                            <SmallerColumn>
                                <StatusButton
                                    className={{
                                        'PAID': 'active',
                                        'WAITING CONFIRMATION': 'warning',
                                        'CREATED': 'warning',
                                    }[o?.status] || 'inactive'}
                                >
                                    {OrderStatus[o?.status]}
                                </StatusButton>
                            </SmallerColumn>
                            <td>
                                R$ {o?.price?.toString()?.replaceAll('.', ',')}
                            </td>
                            <td>
                                {PaymentMethod[o?.payment_method]}
                            </td>
                            <td>
                                {o?.installments}
                            </td>
                            <td>
                                {formatDjangoDate(o?.created)}
                            </td>
                            <td>
                                {o?.link_details?.name}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    </Background>
  )
}
