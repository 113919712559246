import { EventResponse } from '@/Services/api/models/eventModel'
import React, { useEffect, useState } from 'react'
import { ContentContainer, InputContainer, LabelContainer, QRCodeContainer, Title } from './styled';
import QRCode from 'react-qr-code';
import { FormButton } from '@/Components/FormButton';
import { Group } from '@/Services/api/models/ControleDeAcessoModels';
import ControleDeAcessoService from '@/Services/controle-de-acesso/ControleDeAcessoService';
import ApiService from '@/Services/api/ApiService';
import { handlePrintAppConfig } from '@/util/print';
import { Form } from 'react-bootstrap';

interface Props {
    event: EventResponse;
}

export default function AppConfig(props: Props) {
    const [module, setModule] = useState<string>()
    const [modules, setModules] = useState<string[]>([])
    const [options, setOptions] = useState<any>()
    const [groups, setGroups] = useState<Group[]>()
    const [room, setRoom] = useState<number>()
    const [qrCodeData, setQRCodeData] = useState<{auth: string, module?: string, moduleParams?: {room?: number, event: number, attraction?: number, name: string}, event?: string, printerIp?: string, serverUrl?:string, mode: string;}>()
    const [printerIp, setPrinterIp] = useState<string>()
    const [apiAddress, setApiAddress] = useState<string>()
    const [mode, setMode] = useState<string>('CAMERA')

    const getRooms = async () => {
        const response = await ControleDeAcessoService.getRoomsByEvent(props.event?.id)
        const groups = await ControleDeAcessoService.getGroupsByEvent(props.event?.id)
        setGroups(groups)
        setOptions(response)
        
    }

    const getAttractions = async () => {
        const response = await ApiService.attractionFindByEvent(props.event?.id)
        setOptions(response)
    }

    const handlePrintConfig = async () => {
        const data = JSON.stringify(qrCodeData)
        const response = handlePrintAppConfig(
            props.event,
            module === 'CEK'? module : module === 'Capture Control'? `${module} - ${options.find(opt => opt.id === room)?.name}` : `${module} - ${options.find(opt => opt.id === room)?.room_name}`,
            data
        )
    }

    useEffect(() => {
        if(props.event) {
            let m = ['Autoatendimento']
            if (props.event.accessControl) {
                m.push('Controle de Acesso')
            }
            if (props.event.attractionControl) {
                m.push('Capture Control')
            }
            if (props.event.kitQuantityLimit > 0) {
                m.push('CEK')
            }
            setModules(m)
        }
    },[props.event])

    useEffect(() => {
        if(module === 'Controle de Acesso') {
            getRooms()
        }
        if(module === 'Capture Control') {
            getAttractions()
        }
    },[module])

    useEffect(() => {
        if (module && room && module === 'Controle de Acesso') {
            setQRCodeData({
                auth: `Basic ${btoa(`app@${props.event?.id}:app`)}`,
                module: module,
                moduleParams: {
                    event: props.event?.id,
                    room: room,
                    name: options?.find(room => room.id === room)?.room_name
                },
                mode: mode
            })
        }
        if (module && room && module === 'Capture Control') {
            setQRCodeData({
                auth: `Basic ${btoa(`app@${props.event?.id}:app`)}`,
                module: module,
                moduleParams: {
                    event: props.event?.id,
                    attraction: room,
                    name: options?.find(attraction => attraction.id === room)?.name
                },
                mode: mode
            })
        }
        if (module && module === 'CEK') {
            setQRCodeData({
                auth: `Basic ${btoa(`app@${props.event?.id}:app`)}`,
                module: module,
                moduleParams: {
                    event: props.event?.id,
                    name: "Controle de Brinde"
                },
                mode: mode
            })
        }
    },[module, room, mode])

    useEffect(() => {
        if(module === 'Autoatendimento' && printerIp) {
            setQRCodeData({
                auth: `Basic ${btoa(`tablet@${props.event?.id}:app`)}`,
                event: props.event?.id?.toString(),
                printerIp: printerIp,
                serverUrl: apiAddress,
                mode: mode
            })
        }
    },[module,printerIp,apiAddress, mode])

    useEffect(() => {
        console.log(JSON.stringify(qrCodeData))
    },[qrCodeData])


  return (
    <ContentContainer>
        <Title>
            Gerar QR Code de acesso ao app                    
        </Title>
        <Form.Group>
            <Form.Label>
                Módulo
            </Form.Label>
            <Form.Select
                aria-label='Default select example'
                onChange={(e) => setModule(e.target.value)}
            >
                <option value={''}> -- Selecione -- </option>
                {modules?.map((item) => (
                    <option key={item} value={item}>
                        {item}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
        <Form.Group>
            <Form.Label>Modo de leitura</Form.Label>
            <Form.Select
                aria-label='Default select example'
                onChange={(e) => setMode(e.target.value)}
            >
                <option value={''}> -- Selecione -- </option>
                <option key={'CAMERA'} value={'CAMERA'}>
                    Câmera
                </option>
                <option key={'SCANNER'} value={'SCANNER'}>
                    Leitor
                </option>
            </Form.Select>
        </Form.Group>
        {module && module !== 'CEK' && module !== 'Autoatendimento' && <>
            <LabelContainer>
                <label> {module === 'Controle de Acesso'? 'Sala' : 'Atração'} </label>
            </LabelContainer>
            <Form.Select
                    aria-label='Default select example'
                    onChange={(e) => setRoom(Number(e.target.value))}
            >
                    <option value={''}> -- Selecione -- </option>
                    {options?.map((item) => (
                        <option key={item.id} value={item.id}>
                            {module === 'Controle de Acesso'? 
                                `${item.room_name} - ${groups?.find(g => g.id === item.group)?.group_name}`
                            :
                                `${item.name}`
                            }
                        </option>
                    ))}
                </Form.Select>
        </>}
        {module && module === 'Autoatendimento' && <>
            <LabelContainer>
                <label> Endereço da impressora </label>
            </LabelContainer>
            <Form.Control 
                type='text'
                value={printerIp}
                onChange={(e) => setPrinterIp(e?.target?.value)}
            />
            <LabelContainer>
                <label> Endereço da API </label>
            </LabelContainer>
            <Form.Control 
                type='text'
                value={apiAddress}
                onChange={(e) => setApiAddress(e?.target?.value)}
            />
        </>}
        {qrCodeData &&
            <QRCodeContainer>
                <QRCode value={JSON.stringify(qrCodeData)} />
                <FormButton onClick={handlePrintConfig}>
                    Imprimir QR Code
                </FormButton>
            </QRCodeContainer>
        }
    </ContentContainer>
  )
}
