import styled from 'styled-components';

export const WhiteBoxContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    
    padding: 20px;
    margin: 20px;

    table {
        min-height: 150px;
    }

    .header {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 41px;
            height: 22px;
            font-size: ${({ theme }) => theme.fontSize.xs};
        }
    }
    .body {
        display: flex;
        flex-direction: row;
    }

    .tabela {
        overflow: auto;
        padding-bottom: 50px;
        min-height: 70vh;
    }

    th {
        font-weight: 700;
    }

    td,
    th {
        font-size: ${({ theme }) => theme.fontSize.form};
    }

    .dropdown {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            height: 22px;
            width: 50px;
            font-size: ${({ theme }) => theme.fontSize.xs};

            :hover {
                color: white;
            }
        }

        .dropdown-item {
            font-size: ${({ theme }) => theme.fontSize.xs};
            color: ${({ theme }) => theme.colors['gray-700']};
        }
    }
`;

export const RowContainer = styled.div`
    display: grid;
    grid-template-columns: 20% calc(80% - 20px);
    gap: 20px;

    @media (width < 1000px) {
        display: flex;
        flex-direction: column;
    }
`


export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 2%;
    min-height: calc(80vh - 64px);
    gap: 5px;

    @media(width < 1000px) {
        margin-left: unset;
        width: 100%;
        height: unset;
        margin-bottom: 0px;
    }
`

export const MenuItem = styled.div<{selected: boolean, disabled: boolean}>`
    display: flex;
    box-shadow: ${(props) => props?.selected? 'none' : 'rgba(0, 0, 0, 0.1) 0px 0px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'};
    padding: 2%;
    align-items: center;
    gap: 10px;
    cursor: ${(props) => props.selected || props.disabled? 'auto' : 'pointer'};
    height: 50px;
    background-color: ${(props) => props.selected? props.theme.colors['gray-400'] : props?.disabled? props.theme.colors['gray-600'] : '#fff'};
    border-radius: 5px;
`

export const MenuTitle = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
`