import { TButton } from '@/Components/TButton';
import styled from 'styled-components';

export const ConfigureLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
   

    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;

    img {
        width: 350px;
        height: 180px;
    }
`;

export const LabelRow = styled.div`
    display: grid;
    grid-template-columns: 50% 25% 25%;
    padding: 2%
`

export const LabelButton = styled(TButton)`
    margin-bottom: 50px;
`;

export const BoxWithTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    width: 100%
`;

export const Title = styled.h1`
    font-size: ${({ theme }) => theme.fontSize['3xl']};
    font-weight: bold;
    margin-bottom: 20px;
`;

export const Subtitle = styled.p`
    font-weight: bold;
`

export const ResolutionContainer = styled.div``;

export const DrawLabelContainer = styled.div`
    display: grid;
    grid-template-columns: 305px auto;
    background-color: #ccc;
    width: 90%;
    height: 500px;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
   
`;

export const LabelCanva = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100%-305px);
    left: 305px;
    gap: 90px;
    height: 100%;
`

export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    width: 305px;
    height: 490px;
    border-right: 1px solid #fff;
    gap: 10px;
`

export const MenuOption = styled.div`
    display: grid;
    grid-template-columns: 10px 290px;
    background-color: #fff;
    border-radius: 5px;
    width: 300px;
    height: 50px;
    align-items: center;
    margin-right: 5px;
    gap: 20px;
`

export const OptionLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    with: 100%;
`

export const LabelSizes = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    with: 80%;
    gap: 20px;

`

export const Label = styled.div<{ aspectRatio: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    aspect-ratio: ${(props) => props.aspectRatio};
    background-color: #fff;
    border-radius: 5px;
    position: relative;
`
export const Field = styled.div<{name: string; posx: string; posy: string; selected:boolean}>`
    display: flex;
    background-color: #ccc5;
    width: ${(props) => (props.name === 'top' || props.name === 'center')? '490px' : '240px'};
    height: 50px;
    border-radius: 5px;
    left: ${(props) => props.posx};
    top: ${(props) => props.posy};
    position: absolute;
    align-items: center;
    padding: 5px;
    border: ${(props) => props.selected? `4px ${props.theme.colors.steelblue} solid` : 'none'}
`

export const Barcode = styled.img`
    max-height: 50px;
`

export const FieldMenu = styled.div`
    display: flex;
    flex-direction: row;
    top: 20px;
    height: 50px;
    border-radius: 5px;
    background-color: #fff;
    padding: 5px;
    gap: 5px;
`

export const FieldMenuItem = styled.div<{selected: boolean}>`
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-radius: 5px;
    background-color: ${(props) => props.selected? props.theme.colors.steelblue : '#fff'};
    color: ${(props) => props.selected? '#fff' : '#000'};
    align-items: center;
    justify-content: center;
    width: 150px;
    cursor: pointer;
`

export const ConfigContainer = styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
`

export const TestPrint = styled.div`
    display: flex;
    flex-direction: row;
    height: 50px;
    border-radius: 5px;
    background-color: #fff;
    padding: 5px;
    gap: 5px;
    bottom: 20px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;;
    cursor: pointer;
`