import { Link } from '../Link';
import {
    EmpTitle,
    HeaderContainer,
    LogOutButton,
    NavBarContainer,
    NavBarItemContainer,
    NavigationContainer,
    Title,
} from './styled';

import { BiExit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../UseToken';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

interface Props {
    pageTitle: string;
}

export function Header({ pageTitle }: Props) {
    const navigate = useNavigate();
    const user = useToken();

    const { clearToken, token } = useToken();

    const handleLogOut = () => {
        clearToken();
        navigate('/');
        window.location.reload();
    };
    return (
        <HeaderContainer>
            <NavigationContainer>
                <Navbar collapseOnSelect expand='sm'>
                    <Navbar.Brand href={'/'}>
                        <EmpTitle>
                            {' '}
                            <img src={'/assets/images/logo.png'} alt='' />{' '}
                        </EmpTitle>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className='me-auto'>
                            <Nav.Link href='/eventos/consultar'> Eventos </Nav.Link>
                            {token?.user.userGroup === 'ADMIN' && (
                                <>
                                    <Nav.Link href='/usuarios/consultar'> Usuários </Nav.Link>
                                </>
                            )}
                            {token?.user.userGroup === 'SELF_SERVICE' ? (
                                <Nav.Link href='/autoattendant'> Auto-Atendimento </Nav.Link>
                            ) : null}
                            {(token?.user.userGroup === 'ADMIN' || token?.user.userGroup === 'PARTNER_ADMIN') && (
                                <>
                                    <Nav.Link href='/instalacao'> Instalação </Nav.Link>
                                </>
                            )}
                            {token?.user.userGroup === 'ADMIN' && (
                                <>
                                    <Nav.Link href='/configuracao'> Configurações </Nav.Link>
                                </>
                            )}
                        </Nav>

                        <Nav>
                            <Nav.Link eventKey='disabled' disabled>
                                {`Bem-vindo, ${token?.user?.name}`}
                            </Nav.Link>
                            <Nav.Link>
                                <LogOutButton onClick={handleLogOut}>
                                    <BiExit size={15} />
                                    <p> Sair do Sistema </p>
                                </LogOutButton>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </NavigationContainer>
            <Title> {pageTitle} </Title>
        </HeaderContainer>
    );
}
