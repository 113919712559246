import styled from 'styled-components';


export const Title = styled.h1`
    color: ${({ theme }) => theme.colors['gray-700']};
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    font-weight: 700;
    padding: 20px 0px 30px 0px;
    margin: 0;
`

export const Background = styled.div`
    background-color: ${({ theme }) => theme.colors.white};

    padding: 20px;
    margin: 0px 20px 20px 20px;

    table {
        min-height: 150px;
    }

    input[type='file'] {
        display: none;
    }

    .header {
        display: flex;
        flex-direction: row;
        gap: 15px;
        margin-bottom: 15px;

        > button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: ${({ theme }) => theme.fontSize.xs};
        }
    }

    .body {
        display: flex;
        flex-direction: row;
    }

    .tabela {
        overflow: auto;
        padding-bottom: 50px;
    }

    .highlighted {
        background-color: lightpink;
    }

    th {
        font-weight: 700;
    }

    td,
    th {
        font-size: ${({ theme }) => theme.fontSize.form};
    }

    .dropdown {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            height: 22px;
            width: 50px;
            font-size: ${({ theme }) => theme.fontSize.xs};

            :hover {
                color: white;
            }
        }

        .dropdown-item {
            font-size: ${({ theme }) => theme.fontSize.xs};
            color: ${({ theme }) => theme.colors['gray-700']};

            > a:hover,
            > a:focus,
            > a:active {
                background-color: black;
            }

            > *:hover,
            > *:focus,
            > *:active {
                background-color: black;
            }
        }
    }
`;

export const StatusButton = styled.button`
    font-size: ${({ theme }) => theme.fontSize.us};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;

    text-transform: uppercase;
    border-radius: 4px;

    border: none;
    padding: 3px 8px;
`;

export const SmallerColumn = styled.td`
    width: 10%;

    .active {
        background-color: ${({ theme }) => theme.colors.steelblue};
    }

    .inactive {
        background-color: ${({ theme }) => theme.colors['pink-500']};
    }

    .warning {
        background-color: #FFCA2C;
    }
`;

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;

    margin: 10px 0px;
`;

export const FilterFields = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 40vw;
    align-items: center;
`;

export const BalanceContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;

    @media(width < 1000px) {
        flex-direction: column;
    }
`

export const BalanceCard = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    background-color: ${({ theme }) => theme.colors['gray-400']};
    width: 100%;
    height: 150px;
    padding: 10px;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
`

export const Balance = styled.div`
    font-size: ${({ theme }) => theme.fontSize['2xl']}
`

export const WithdrawalsContainer = styled.div`
    display: flex;
    margin-top: 20px;
    font-size: ${({ theme }) => theme.fontSize['xl']};
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
`