import { Background } from '@/Components/Background';
import ModalLoading from '@/Components/ModalLoading';
import React, { useEffect, useState } from 'react'
import { GetAttractionsContainer, Grow, HeaderRow, MenuRow, SmallerColumn, WhiteBoxContainer } from './styled';
import { Header } from '@/Components/Header';
import { FormButton } from '@/Components/FormButton';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, DropdownButton, Form, Table } from 'react-bootstrap';
import { Attraction } from '@/Services/api/models/attractionsModels';
import ApiService from '@/Services/api/ApiService';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { toast } from 'react-toastify';
import { useToken } from '@/Components/UseToken';

export default function Atracoes() {
    const navigate = useNavigate()
    const { eventID } = useParams()
    const [eventId, setEventId] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [attractions, setAttractions] = useState<Attraction[]>()
    const [userList, setUserList] = useState<UsersResponse[]>()
    const [attractionsSelected, setAttractionsSelected] = useState<Attraction[]>([])
    const { token, setAdminEvent } = useToken();

    const getEventId = async () => {
        if (eventID) {
            setEventId(eventID)
        }
        else {
            console.log(token?.user)
            const eventId = token?.user?.events?.[0]
            setEventId(eventId?.toString())
        }
    }


    const handleGetUsersList = async () => {
        setIsLoading(true)
        try{
            const data = await ApiService.getAllUsers()
            setUserList(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const getEventAttractions = async () => {
        setIsLoading(true)
        try{
            if(token?.user?.userGroup === 'ADMIN') {
                const data = await ApiService.attractionFindByEvent(Number(eventId))
                setAttractions(data)
            }
            else {
                const data = await ApiService.attractionFindByUser(Number(token?.user?.id))
                setAttractions(data)
            }    
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const handleSelectAttraction = (attraction: Attraction) => {
        if(attractionsSelected?.includes(attraction)) {
            setAttractionsSelected(attractionsSelected.filter(att => att !== attraction))
        }
        else {
            setAttractionsSelected([...attractionsSelected,attraction])
        }
    }

    const handleDeleteAttraction = async (attraction: Attraction) => {
        setIsLoading(true)
        try{
            const response = await ApiService.attractionDelete(attraction.id)
            toast.success('Atração excluída')
            setAttractionsSelected(attractionsSelected.filter(att => att !== attraction))
            
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
            getEventAttractions()
        }
    }

    const handleMassAction = async (f: Function) => {
        attractionsSelected.map((attraction) => {
            f(attraction)
        })
    }

    useEffect(() => {
        if(eventId) {
            getEventAttractions()
            handleGetUsersList()
        }
    },[eventId])

    useEffect(() => {
        if(token) {
            getEventId()
        }
    },[token])

  return (
    <Background>
            <ModalLoading isActive={isLoading} />
            <GetAttractionsContainer>
                <Header pageTitle='Controle de Atrações' />
                <HeaderRow>
                    <FormButton onClick={() => navigate(`/evento/${eventId}`)}>
                        Voltar para a Dashboard
                    </FormButton>
                </HeaderRow>
                <WhiteBoxContainer>
                    {token?.user?.userGroup === 'ADMIN' &&
                        <MenuRow>
                            <Grow>
                                <FormButton onClick={() => navigate(`/modulos/cess/adicionar/${eventId}`)}>
                                    Novo
                                </FormButton>
                            </Grow>
                            {(attractionsSelected?.length > 0) &&  
                                <Grow>
                                    <FormButton 
                                        className='bg-danger' 
                                        onClick={() => handleMassAction(handleDeleteAttraction)}
                                    >
                                        Excluir Selecionados
                                    </FormButton>
                                </Grow>
                            }
                        </MenuRow>
                    }
                    <div className='body'></div>
                    <div className='tabela'>
                        {attractions && (
                            <Table hover>
                                <thead>
                                    <tr>
                                        {token?.user?.userGroup === 'ADMIN' &&
                                            <th></th>
                                        }
                                        <th></th>
                                        <th>Nome</th>
                                        <th>Local</th>
                                        <th>Usuário</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attractions.map((attraction) => 
                                        <tr onDoubleClick={() => navigate(`/modulos/cess/consultar/${eventId}/${attraction.id}`)}>
                                            {token?.user?.userGroup === 'ADMIN' &&
                                                <td>
                                                    <Form.Check
                                                        type={'checkbox'}
                                                        onChange={() => handleSelectAttraction(attraction)}
                                                        checked={attractionsSelected?.includes(attraction)}
                                                    />
                                                </td>
                                            }
                                            <td>
                                                <FormButton onClick={() => navigate(`/modulos/cess/consultar/${eventId}/${attraction.id}`)}>
                                                    Ver Lista
                                                </FormButton>
                                            </td>
                                            <td>
                                                {attraction.name}
                                            </td>
                                            <td>
                                                {attraction.local}
                                            </td>
                                            <td>
                                                {userList?.find((user) => user.id === attraction?.userId)?.name}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </WhiteBoxContainer>
            </GetAttractionsContainer>
        </Background>
  )
}
