import { Background } from '@/Components/Background';
import { Barcode, BoxWithTitle, ConfigContainer, ConfigureLabelContainer, DrawLabelContainer, Field, FieldMenu, FieldMenuItem, Label, LabelButton, LabelCanva, LabelRow, LabelSizes, Menu, MenuOption, OptionLabel, Subtitle, TestPrint, Title } from './styled';
import ModalLoading from '@/Components/ModalLoading';
import { Header } from '@/Components/Header';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiService from '@/Services/api/ApiService';
import { EventResponse } from '@/Services/api/models/eventModel';
import { Etiquetas, IEtiquetas } from '@/util/etiquetas';
import { Col, Form, Row } from 'react-bootstrap';
import { TButton } from '@/Components/TButton';
import { EventTitle } from '@/Pages/Participants/Consultar/styled';
import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import { toBase64 } from '@/util/base64';
import { imageToZ64 } from '@/util/convertImage';
import { Clear, Delete, QrCode, ShortText, TextDecrease, TextIncrease } from '@mui/icons-material';
import { BiBarcode, BiBarcodeReader, BiPrinter } from 'react-icons/bi';
import { FaBarcode, FaBold } from 'react-icons/fa';
import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core';
import DraggableMenuItem from '@/Components/DraggableMenuItem/DraggableMenuItem';
import QRCode from 'react-qr-code';
import generateZPL from '@/util/generateZpl';
import { handlePrintTicket } from '@/util/print';

interface LabelConfig {
    top?: {
        value: string;
        type: 'bold' | 'normal';
        fontSize: 'large' | 'small';
    },
    center?: {
        value: string;
        type: 'bold' | 'normal';
        fontSize: 'large' | 'small';
    },
    topCenter?: {
        value: string;
        type: 'bold' | 'normal';
        fontSize: 'large' | 'small';
    },
    bottomCenter?: {
        value: string;
        type: 'bold' | 'normal';
        fontSize: 'large' | 'small';
    },
    bottomLeft?: {
        value: string;
        type: 'bold' | 'normal';
        fontSize: 'large' | 'small';
    },
    bottomRight?: {
        value: string;
        type: 'bold' | 'normal';
        fontSize: 'large' | 'small';
    }
}

export function ConfigureLabel() {
    const [isLoading, setIsLoading] = useState(false);
    const [eventInfo, setEventInfo] = useState<EventResponse>();
    const [preconfigured, setPreconfigured] = useState<boolean>(false)
    const [labelDesign, setLabelDesign] = useState('');
    const [labelResolution, setLabelResolution] = useState('');
    const [labelImage, setLabelImage] = useState('')
    const printerModels = ['ZEBRA','ELGIN']
    const [printerModel, setPrinterModel] = useState<string>('')
    const [labelQty, setLabelQty] = useState<number>(1)
    const [selectedField, setSelectedField] = useState<string>()
    const [labelConfig, setLabelConfig] = useState<LabelConfig>()
    const { eventId } = useParams();
    const navigate = useNavigate();

    const labelSizes = [
        '7x3',
        '8x3',
        '8x4',
        '9x4',
        '9x5',
        '10x4',
        '10x5',
        '10x6',
        'Pulseira'
    ] 

    const getEventInfo = async () => {
        try {
            setIsLoading(true);

            if (!eventId) {
                throw new Error('Evento não informado na URL');
            }

            const eventInfo = await ApiService.getEventById(+eventId);

            setEventInfo(eventInfo);
            setLabelDesign(findLabelId(eventInfo.labelResolution, eventInfo.labelConfiguration));
            setLabelResolution(eventInfo.labelResolution);
            setPrinterModel(eventInfo.printerModel);
            setLabelQty(eventInfo?.labelPrintCount)
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const findLabelId = (size: string, zplCode: string): string | undefined => {
        for (const label of Etiquetas) {
            const sizes = label.sizes;
            for (const labelSize in sizes) {
                if (sizes.hasOwnProperty(labelSize)) {
                    if (size === labelSize && zplCode === sizes[labelSize]) {
                        return label.id;
                    }
                }
            }
        }
        return undefined;
    }

    const changeLabelConfiguration = async () => {
        try {
            setIsLoading(true);
            const etiqueta = 
                preconfigured?
                    labelDesign
                :
                    generateZPL(labelConfig, labelResolution)
            
            if (
                etiqueta === findLabelId(labelResolution, eventInfo?.labelConfiguration) &&
                labelResolution === eventInfo.labelResolution &&
                printerModel === eventInfo.printerModel &&
                labelQty === eventInfo?.labelPrintCount

            ) {
                toast.success('Etiqueta alterada com sucesso');
                navigate('/');
                return;
            }

            if (preconfigured && !labelDesign || !labelResolution) {
                throw new Error('Nenhuma etiqueta selecionada');
            }

            if (!eventInfo) {
                throw new Error('Nenhum evento encontrado na URL');
            }
            const backgroundImage = await ApiService.getBackgroundImage(Number(eventId))
            await ApiService.upInsertEvent({
                ...eventInfo,
                backgroundImage: backgroundImage,
                labelConfiguration: preconfigured? Etiquetas.find(l => l.id.includes(labelDesign))?.sizes?.[labelResolution]?.replaceAll('[[IMAGE_ZPL]]',labelImage) : etiqueta ?? 'No label',
                labelResolution: labelResolution,
                printerModel: printerModel,
                labelPrintCount: labelQty
            });

            toast.success('Etiqueta alterada com sucesso');
            navigate('/');
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        if (selectedFile) {
            const base64 = await toBase64(selectedFile)
            const res = await imageToZ64(base64,{rotate: 'L'})
            setLabelImage(`^GFA,${res.length},${res.length},${res.rowlen},${res.z64}`)
        }
    }

    const handleDragEnd = (event: { over: any; active: any; }) => {
        const { over, active } = event;

        if (over && active) {
            const fieldId = active.id; 
            const dropZoneId = over.id; 
            let config = labelConfig
            if(config) {
                config[dropZoneId] = {
                    value: fieldId,
                    type: 'normal',
                    fontSize: 'small'
                }
            } else {
                config = {
                    [dropZoneId]: {
                        value: fieldId,
                        type: 'normal',
                        fontSize: 'small'
                    }
                }
            }
            setLabelConfig(config)
        }
    }

    const handleMakeBold = () => {
        setLabelConfig((config) => ({...config, [selectedField]: {
            ...config[selectedField],
            type: config[selectedField]?.fontSize === 'bold'? 'normal' : 'bold'
        }}))
    }

    const handleChangeFontSize = () => {
        setLabelConfig((config) => ({...config, [selectedField]: {
            ...config[selectedField],
            fontSize: config[selectedField]?.fontSize === 'large'? 'small' : 'large'
        }}))
    }

    const handleUnselect  = () => {
        setLabelConfig((config) => ({...config, [selectedField]: {
            value: null,
            type: null,
            fontSize: null
        }}))
    }



    function Droppable(props: { id: string; posx: string; posy: string; }) {
        const {isOver, setNodeRef} = useDroppable({
            id: props.id,
        });
        return (
            <Field id={props.id} name={props.id} selected={selectedField === props.id} ref={setNodeRef} posx={props.posx} posy={props.posy}>
                {labelConfig?.[props?.id]?.value && renderFieldContent(labelConfig[props?.id].value)}
            </Field>
        );
    }

    const fields = [
        {
            name: 'Nome',
            icon: <ShortText />,
            value: '[[NOME_1]]',
        },
        {
            name: 'Empresa',
            icon: <ShortText />,
            value: '[[EMPRESA]]'
        },
        {
            name: 'Cargo',
            icon: <ShortText />,
            value: '[[CARGO]]'
        },
        {
            name: 'Cidade',
            icon: <ShortText />,
            value: '[[CIDADE]]'
        },
        {
            name: 'Estado',
            icon: <ShortText />,
            value: '[[ESTADO]]'
        },
        {
            name: 'Categoria',
            icon: <ShortText />,
            value: '[[CATEGORIA]]'
        },
        {
            name: 'QR CODE',
            icon: <QrCode />,
            value: '[[QRCODE]]'
        },
        {
            name: 'Código de Barras',
            icon: <FaBarcode />,
            value: '[[BARCODE]]'
        },
    ]

    const renderFieldContent = (field: string) => {
        switch (field) {
            case 'QR CODE':
                return <QRCode value="example" size={50} />;
            case 'Código de Barras':
                return <Barcode src="/assets/images/barcode.jpg" alt="Barcode" />;
            default:
                return <span>{field}</span>;
        }
    };

    const handlePrintTest = async () => {
        setIsLoading(true)
        const zpl = generateZPL(labelConfig, labelResolution)
        if(zpl) {
            await handlePrintTicket(eventInfo, {
                name: 'TESTE', 
                lastName: 'TESTE',
                documentNumber: '00000000000',
                companyName: 'Empresa',
                companyPosition: 'Teste',
                id: 0,
                eventId: 0,
                state: 'SP',
                city: 'São Paulo',
                cellPhoneNumber: '+55 11 999999999',
                commercialPhoneNumber: '',
                email: 'teste@teste.com',
                hasDivergence: false,
                externalCodeAux: 0,
                isImportedFromExcel: false,
                registrationOrigin: 'ADMIN',
                checkIn: false,
                checkinDate: '',
                active: true,
                categoryId: 1,
                cekWithdraw: false,
                cekWithdrawDate: '',
                linkOrigin: '',
                additionalInformation: '',
                additionalCategory: null,
                checkInDuration: 0,
                checkInQrCode: null
            },false,null,zpl)
        }
        setIsLoading(false)
    }
    

    useEffect(() => {
        getEventInfo();
    }, []);

    useEffect(() => {
        const selectElement = (e) => {
            if(['top', 'center', 'bottomLeft', 'bottomCenter', 'bottomRight']?.includes(e?.target?.id)) {
                setSelectedField(e?.target?.id)
            }
        }

        window.addEventListener('click', selectElement)

        return () => {
            window.removeEventListener('click', selectElement)
        }
    },[])

    useEffect(() => {
        if(selectedField) {

        }
    },[selectedField])

    useEffect(() => {
        if(labelResolution === 'Pulseira') {
            setPreconfigured(true)
            setLabelResolution('27x2,5')
        }
    },[labelResolution])

    return (
        <Background>
            <ModalLoading isActive={isLoading} />
            <Header pageTitle='Configurar Etiqueta' />
            <EventTitle>
                {' '}
                <span> Evento: </span> {eventInfo?.description} ({eventInfo?.id}){' '}
            </EventTitle>
            <WhiteBackground>
                <BoxWithTitle>
                    <Title> Tamanho da Etiqueta </Title>
                    <ConfigContainer>
                        <Form.Group as={Row} className='mb-3'>
                            <Col>
                                <Form.Select value={labelResolution} onChange={(e) => setLabelResolution(e.target.value)}>
                                    {labelSizes.map((item) => (
                                        <option key={item} value={item}>
                                            {' '}
                                            {item}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </ConfigContainer>
                    <Title> Design </Title>
                    {!preconfigured?
                        <DndContext onDragEnd={handleDragEnd}>

                            <DrawLabelContainer>
                                <Menu>
                                    {fields.map((f) => 
                                        <DraggableMenuItem name={f?.name} icon={f?.icon} value={f?.value}/>
                                    )}
                                </Menu>
                                <LabelCanva>
                                    <FieldMenu>
                                        <FieldMenuItem selected={labelConfig?.[selectedField]?.fontSize === 'large'} onClick={handleChangeFontSize}>
                                            <TextIncrease />
                                            Fonte Grande
                                        </FieldMenuItem>
                                        <FieldMenuItem selected={labelConfig?.[selectedField]?.fontSize === 'small'} onClick={handleChangeFontSize}>
                                            <TextDecrease />
                                            Fonte Pequena
                                        </FieldMenuItem>
                                        <FieldMenuItem selected={labelConfig?.[selectedField]?.type === 'bold'} onClick={handleMakeBold}>
                                            <FaBold />
                                            Negrito
                                        </FieldMenuItem>
                                        <FieldMenuItem selected={false} onClick={handleUnselect}>
                                            <Clear />
                                            Limpar
                                        </FieldMenuItem>
                                    </FieldMenu>
                                    <Label aspectRatio={`${labelResolution?.split('x')[0]}/${Number(labelResolution?.split('x')[1])}`}>
                                        <Droppable id={'top'} key={'top'} posx={'5px'} posy={`calc((500px/${Number(labelResolution?.split('x')[0])/Number(labelResolution?.split('x')[1])})/3 - 55px)`}/>
                                        <Droppable id={'center'} key={'center'} posx={'5px'} posy={`calc((500px/${Number(labelResolution?.split('x')[0])/Number(labelResolution?.split('x')[1])})/3 + 5px)`}/>
                                        <Droppable id={'bottomLeft'} key={'bottomLeft'} posx={'5px'} posy={`calc((500px/${Number(labelResolution?.split('x')[0])/Number(labelResolution?.split('x')[1])})/3 + 65px)`}/>
                                        <Droppable id={'bottomRight'} key={'bottomRight'} posx={'255px'} posy={`calc((500px/${Number(labelResolution?.split('x')[0])/Number(labelResolution?.split('x')[1])})/3 + 65px)`} />
                                    </Label>
                                    <TestPrint onClick={handlePrintTest}>
                                        <BiPrinter />
                                        Imprimir teste
                                    </TestPrint>
                                </LabelCanva>
                            </DrawLabelContainer>
                        </DndContext>
                    :
                        <ConfigureLabelContainer>
                            <LabelRow>
                                <Subtitle> Etiqueta </Subtitle>
                                <Subtitle> Tamanhos disponíveis </Subtitle>
                                <Subtitle> Campos </Subtitle>
                            </LabelRow>
                            {Etiquetas?.filter((l) => Object.keys(l?.sizes)?.includes(labelResolution)).map((label, index) => {
                                return (
                                    <LabelRow style={{backgroundColor: index%2===0? '#D9D9D9' : '#FFF'}}>
                                        <Form.Check
                                            type={'radio'}
                                            label={<img src={label.imagePath} alt='' />}
                                            name='labelDesign'
                                            checked={labelDesign === label.id}
                                            onChange={() => setLabelDesign(label.id)}
                                        />
                                        <Form.Label>{Object.keys(label?.sizes)?.map((size, index) => index === Object.keys(label?.sizes)?.length - 1? `${size}` : `${size}, `)}</Form.Label>
                                        <Form.Label>{label?.fields}</Form.Label>
                                    </LabelRow>
                                );
                            })}
                        </ConfigureLabelContainer>
                    }
                </BoxWithTitle>
                <LabelButton onClick={() => setPreconfigured(!preconfigured)}>
                    {preconfigured? 'Personalizar etiqueta' : 'Usar etiquetas pré-configuradas'}
                </LabelButton>
                {labelDesign === 'label11' && 
                    <BoxWithTitle>
                        <Title> Imagem na Pulseira </Title>
                        <Form.Control type='file' onChange={handleUploadImage}/>
                    </BoxWithTitle>
                }
                <BoxWithTitle>
                    <Title>Outras Configurações</Title>
                    <ConfigContainer>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label>
                                Modelo da impressora
                            </Form.Label>
                            <Col>
                                <Form.Select value={printerModel} onChange={(e) => setPrinterModel(e.target.value)}>
                                    {printerModels.map((item) => (
                                        <option key={item} value={item}>
                                            {' '}
                                            {item}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label>
                                Cópias por participante
                            </Form.Label>
                            <Col>
                                <Form.Control value={labelQty} type='number' min={1} onChange={(e) => setLabelQty(parseInt(e.target.value))} />
                            </Col>
                        </Form.Group>
                    </ConfigContainer>
                </BoxWithTitle>
                <LabelButton onClick={changeLabelConfiguration}>
                    {' '}
                    Alterar Configurações{' '}
                </LabelButton>
            </WhiteBackground>
        </Background>
    );
}
