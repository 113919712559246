import React, { useEffect, useState } from 'react'
import { Background, Balance, BalanceCard, BalanceContainer, FilterContainer, FilterFields, SmallerColumn, StatusButton, Title, WithdrawalsContainer } from './styled'
import { Dropdown, DropdownButton, Form, Table } from 'react-bootstrap'
import RsvpService from '@/Services/rsvp/RsvpService'
import { BalanceResponse, OrderResponse, OrderStatus, PaymentMethod, WithdrawalsResponse } from '@/Services/api/models/rsvpModels';
import { formatDjangoDate } from '@/util/FormatDate';
import ModalLoading from '@/Components/ModalLoading';
import { toast } from 'react-toastify';
import { FormButton } from '@/Components/FormButton';

interface Props {
    eventId: string;
}

export default function Financeiro(props:Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [balance, setBalance] = useState<BalanceResponse>()
    const [withdrawals, setWithdrawals] = useState<WithdrawalsResponse>()

    const getBalance = async () => {
        setIsLoading(true)
        const data = await RsvpService.getRecipientBalance(props.eventId)
        setBalance(data)
        setIsLoading(false)
    }

    const getWithdrawals = async () => {
        setIsLoading(true)
        const data = await RsvpService.getRecipientWithdrawals(props.eventId)
        setWithdrawals(data)
        setIsLoading(false)
    }

    useEffect(() => {
        getBalance()
        getWithdrawals()
    },[])


  return (
    <Background>
        <ModalLoading isActive={isLoading}/>
        <Title>
            Financeiro
        </Title>
        <BalanceContainer>
            <BalanceCard>
                Saldo Disponível
                {!isLoading &&
                    <Balance>
                        R$ {Number(balance?.available_amount/100)?.toFixed(2)?.replace('.',',')}
                    </Balance>
                }
                <FormButton>
                    Nova transferência
                </FormButton>
            </BalanceCard>
            <BalanceCard>
                Saldo a Receber
                {!isLoading &&
                    <Balance>
                        R$ {(Number(balance?.waiting_funds_amount)/100)?.toFixed(2)?.replace('.',',')}
                    </Balance>
                }
            </BalanceCard>
            <BalanceCard>
                Saldo transferido
                {!isLoading && 
                    <Balance>
                        R$ {(Number(balance?.transferred_amount)/100)?.toFixed(2)?.replace('.',',')}
                    </Balance>
                }
            </BalanceCard>
        </BalanceContainer>
        <WithdrawalsContainer>
            Transferências
            <div className={'table'}>
                <Table hover id='orderTable'>
                    <thead>
                        <tr>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Criado</th>
                            <th>Atualizado</th>
                            <th>Banco</th>
                            <th>Agência</th>
                            <th>Conta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {withdrawals?.data?.map((w) => 
                            <tr>
                                <th>R$ {(Number(w?.amount)/100)?.toFixed(2)?.replace('.', ',')}</th>
                                <th>{w?.status}</th>
                                <th>{w?.created_at}</th>
                                <th>{w?.updated_at}</th>
                                <th>{w?.bank_account?.bank}</th>
                                <th>{w?.bank_account?.branch_number} - {w?.bank_account?.branch_check_digit ?? '0'}</th>
                                <th>{w?.bank_account?.account_number} - {w?.bank_account?.account_check_digit ?? '0'}</th>
                            </tr>
                            
                        )}
                    </tbody>
                </Table>
            </div>
        </WithdrawalsContainer>
    </Background>
  )
}
