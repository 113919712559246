import styled from 'styled-components';

export const WhiteBoxContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;

    @media screen and (max-width: 705px) {
        flex-direction: column;
        .addField {
            min-width: 50vw;
        }
    }

    .header {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 41px;
            height: 22px;
            font-size: ${({ theme }) => theme.fontSize.xs};
        }
    }
    .body {
        display: flex;
        flex-direction: row;
    }

    .tabela {
        overflow: auto;
        padding-bottom: 50px;
        min-height: 70vh;
    }

    th {
        font-weight: 700;
    }

    td,
    th {
        font-size: ${({ theme }) => theme.fontSize.form};
    }

    .dropdown {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            height: 22px;
            width: 50px;
            font-size: ${({ theme }) => theme.fontSize.xs};

            :hover {
                color: white;
            }
        }

        .dropdown-item {
            font-size: ${({ theme }) => theme.fontSize.xs};
            color: ${({ theme }) => theme.colors['gray-700']};
        }
    }

    .hiddenInput {
        display: None
    }

`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors['gray-700']};
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    font-weight: 700;
    padding: 20px 0px 30px 0px;
    margin: 0;
`
export const NewDrawingBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
`

export const FilterItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`

export const HintText = styled.div`
    font-size: ${({theme}) => theme.fontSize.xs};
    color: ${({theme}) => theme.colors['gray-700']};
    height: 100%;
`

export const DrawingResult = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
`