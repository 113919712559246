import { EventResponse } from '@/Services/api/models/eventModel';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import { AppConfigLabel, Etiquetas, IEtiquetas, ProtocoloMalex } from './etiquetas';
import { ParticipantCategories } from '@/Services/api/models/participantCategoryModel';
import ApiService from '@/Services/api/ApiService';
import { MalexLabelData } from '@/Services/api/models/malexModels';

export const handlePrintTicket = async (
    event: EventResponse,
    participant: GetParticipantResponse,
    malex?: boolean,
    malexData?: MalexLabelData,
    zplTest?: string
) =>
    new Promise(async (resolve, reject) => {
        const categories:ParticipantCategories[] = await ApiService.getParticipantCategories()
        const etiqueta = malex? ProtocoloMalex.sizes['10x6'] : zplTest? zplTest : event.labelConfiguration
        const printer = event.printerModel ?? 'ZEBRA'

        if (!etiqueta) {
            return reject(new Error('Nenhuma etiqueta configurada'));
        }
        let zebraCode = etiqueta
        
        zebraCode = zebraCode?.replaceAll('[[EMPRESA]]', participant.companyName?.toString().slice(0,40) ?? '');

        zebraCode = zebraCode?.replaceAll('[[CARGO]]', (participant?.companyPosition?.length > 30? participant?.companyPosition?.toString()?.slice(0,30) + '\&' + participant?.companyPosition?.toString()?.slice(30) : participant.companyPosition?.toString()) ?? '');

        zebraCode = zebraCode?.replaceAll('[[BARCODE]]', participant.id?.toString() ?? '');

        zebraCode = zebraCode?.replaceAll('[[QRCODE]]', event?.qrCodeUrl? `${event?.qrCodeUrl}/?participantId=${participant.id?.toString()}` : `https://rentpass.com.br/?participantId=${participant.id?.toString()}`);

        zebraCode = zebraCode?.replaceAll(
            '[[NOME_1]]',
            `${participant.name?.toString()?.toUpperCase()} ${
                !participant.lastName?.toString()?.toUpperCase() ? '' : participant.lastName?.toString().split(' ').at(-1)?.toUpperCase()
            }`,
        );

        zebraCode = zebraCode?.replaceAll('[[NOME_2]]', participant.name?.toString()?.toUpperCase())
        zebraCode = zebraCode?.replaceAll('[[NOME_3]]', (!participant.lastName?.toString()?.toUpperCase() ? '' : participant.lastName?.toString().split(' ').at(-1)?.toUpperCase()))
        if(participant?.additionalCategory === 'Experiência Camarote HEINEKEN') {
            zebraCode = zebraCode?.replaceAll('[[CATEGORIA]]', 'Experiência HEINEKEN')
        } else {
            zebraCode = zebraCode?.replaceAll('[[CATEGORIA]]', participant?.additionalCategory ?? '')
        }
        zebraCode = zebraCode?.replaceAll('[[ESTADO]]', participant.state)
        zebraCode = zebraCode?.replaceAll('[[CIDADE]]', participant.city)

        if(malex) {
            const items = malexData.items.map((item,index) => {
                return `^CI27^FT52,${302+index*30}^A@N,37,38,ARI000.FNT^FH^CI28^FD${item}^FS`
            })
            const itemsString = items.join('')
            zebraCode = zebraCode?.replace('[[ITENS]]', itemsString)
            zebraCode = zebraCode?.replaceAll('[[USUARIO]]', malexData.user)
            zebraCode = zebraCode?.replaceAll('[[DATA]]', malexData.dateTime)
            zebraCode = zebraCode?.replaceAll('[[EVENTO]]', event.description)
        }


        for(let i = 0; i<event.labelPrintCount;i++) {
            if(printer === 'ELGIN') {
                const elgin = 'http://127.0.0.1:2001/eti/v1/write'
                zebraCode = zebraCode?.replaceAll('.FNT','.TTF')
                try{
                    fetch(elgin, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        method: 'POST',
                        body: JSON.stringify({dados: zebraCode})
                    })
                    .then(res => res.json())
                    .then((response) => {
                        if(response.mensagem === 'SUCESSO') {
                            return resolve('Impressão realizada');
                        }
                    })
                } catch (error) {
                    return reject(new Error('Erro ao se comunicar com a impressora'))
                }
            }
    
            if(printer === 'ZEBRA') {
                const BrowserPrint = (window as any).BrowserPrint;
                if (!BrowserPrint) {
                    return reject(new Error('Falha ao se comunicar com a impressora'));
                }
        
                BrowserPrint.getLocalDevices((devices: any) => {
                    if (!devices.printer) {
                        reject(new Error('Erro ao se comunicar com a impressora'));   
                    }
                    devices.printer[0].send(
                        zebraCode,
                        () => {
                            resolve('Impressão realizada');
                        },
                        () => {
                            reject(new Error('Erro ao imprimir layout'));
                        },
                    );
                });
            }
        }
    });

    export const handlePrintAppConfig = async (
        event: EventResponse,
        config: string,
        data: string
    ) =>
        new Promise(async (resolve, reject) => {
            const etiqueta = AppConfigLabel.sizes['7x3']
            const printer = event.printerModel ?? 'ZEBRA'
    
            if (!etiqueta) {
                return reject(new Error('Nenhuma etiqueta configurada'));
            }
            let zebraCode = etiqueta
            
            zebraCode = zebraCode?.replaceAll('[[CONFIG]]', config);
            zebraCode = zebraCode?.replaceAll('[[QRCODE]]', data);
    
    
            if(printer === 'ELGIN') {
                const elgin = 'http://127.0.0.1:2001/eti/v1/write'
                zebraCode = zebraCode?.replaceAll('.FNT','.TTF')
                try{
                    fetch(elgin, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        method: 'POST',
                        body: JSON.stringify({dados: zebraCode})
                    })
                    .then(res => res.json())
                    .then((response) => {
                        if(response.mensagem === 'SUCESSO') {
                            return resolve('Impressão realizada');
                        }
                    })
                } catch (error) {
                    return reject(new Error('Erro ao se comunicar com a impressora'))
                }
            }
    
            if(printer === 'ZEBRA') {
                console.log(zebraCode)
                const BrowserPrint = (window as any).BrowserPrint;
                if (!BrowserPrint) {
                    return reject(new Error('Falha ao se comunicar com a impressora'));
                }
        
                BrowserPrint.getLocalDevices((devices: any) => {
                    if (!devices.printer) {
                        reject(new Error('Erro ao se comunicar com a impressora'));   
                    }
                    devices.printer[0].send(
                        zebraCode,
                        () => {
                            resolve('Impressão realizada');
                        },
                        () => {
                            reject(new Error('Erro ao imprimir etiqueta'));
                        },
                    );
                });
            }
        });
