import React, { useState } from 'react'
import { DrawingResult, FilterItem, HintText, NewDrawingBox, Title, WhiteBoxContainer } from './styled'
import { Form, ListGroup, Row } from 'react-bootstrap';
import { FormButton } from '../FormButton';
import ApiService from '@/Services/api/ApiService';
import { toast } from 'react-toastify';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import ModalParticipantInfo from '../ModalParticipantInfo';
interface Props {
    eventId: string;
}

export default function Drawing(props: Props) {
    const [filters, setFilters] = useState<string[]>()
    const [winners, setWinners] = useState<number>(1)
    const [winnersList, setWinnersList] = useState<GetParticipantResponse[]>()
    const [detailsModal, setDetailsModal] = useState<boolean>(false)
    const [selectedParticipant, setSelectedParticipant] = useState<GetParticipantResponse>()

    const filterTypes = [
        'Check-in realizado'
    ]

    const handleRemoveFilter = (f: string) => {
        setFilters(filters => [...new Set(filters?.filter(ff => ff !== f))])
    }

    const handleDraw = async () => {
        let filteredParticipants:GetParticipantResponse[]
        try {
            const participants = await ApiService.getAllParticipantsByEvent(Number(props.eventId))
            filteredParticipants = participants?.filter(p => {
                if(filters?.includes('Check-in realizado')) {
                    return p?.checkIn
                }
                else {
                    return true
                }
            })
        }
        catch {
            toast.error('Não foi possível obter a lista de participantes')
        }
        if(filteredParticipants) {
            let winnersL:Set<GetParticipantResponse> = new Set()
            while ((winnersL.size < winners) && (winnersL.size < filteredParticipants.length)){
                const random = Math.random()
                const index = Math.floor(random * (filteredParticipants?.length))
                const win = filteredParticipants?.[index]
                winnersL.add(win)
            }
            setWinnersList([...winnersL])
            console.log([...winnersL])
        }
    }

  return (
    <WhiteBoxContainer>
        <ModalParticipantInfo show={detailsModal} onButtonClick={() => setDetailsModal(false)} participant={selectedParticipant} />
        <Title>
            Sorteio
        </Title>
        <NewDrawingBox>
            <Title>Novo Sorteio</Title>
            <Form>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label>
                        Filtrar participantes
                    </Form.Label>
                    <Form.Select onChange={(e) => e.target.value !== '' && setFilters(f => f? [...new Set([...f, e.target.value])] : [e.target.value])}>
                        <option value={''}>Todos</option>
                        {filterTypes?.map((f) =>
                            <option value={f}>{f}</option>
                        )}
                    </Form.Select>
                </Form.Group>
                <ListGroup>
                    {filters?.map((f) => 
                        <ListGroup.Item onClick={() => handleRemoveFilter(f)}>
                            <FilterItem>
                                {f}
                                <HintText>
                                    Clique para remover
                                </HintText>
                            </FilterItem>
                        </ListGroup.Item>
                    )}
                </ListGroup>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label>
                        Quantidade de ganhadores
                    </Form.Label>
                    <Form.Control 
                        onChange={(e) => setWinners(Number(e?.target?.value))}
                        type='number' 
                    />
                </Form.Group>
            </Form>
            <FormButton onClick={handleDraw}>
                Sortear
            </FormButton>
        </NewDrawingBox>
        <DrawingResult>
        <ListGroup>
            {winnersList?.map((participant) => 
                <ListGroup.Item onClick={() => {setDetailsModal(true); setSelectedParticipant(participant)}}>
                    <FilterItem>
                        {`${participant?.name}`}
                        <HintText>
                            Clique para ver detalhes
                        </HintText>
                    </FilterItem>
                </ListGroup.Item>
            )}
        </ListGroup>
        </DrawingResult>
    </WhiteBoxContainer>
  )
}
