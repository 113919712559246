import { useTheme } from 'styled-components';
import {
    AlredyCheckedInContainer,
    ContentContainer,
    ImportExcel,
    InputContainer,
    LabelContainer,
    ModalContainer,
    ModalFooterButton,
    ModalFooterContainer,
    QRCodeContainer,
    Title,
} from './styled';
import TModal from '../TModal';
import { FormButton } from '../FormButton';
import { useEffect, useRef, useState } from 'react';
import CloseButton from '../CloseButton';
import { Form } from 'react-bootstrap';
import { EventResponse } from '@/Services/api/models/eventModel';
import ControleDeAcessoService from '@/Services/controle-de-acesso/ControleDeAcessoService';
import { Group } from '@/Services/api/models/ControleDeAcessoModels';
import QRCode from 'react-qr-code';
import ApiService from '@/Services/api/ApiService';
import { handlePrintAppConfig } from '@/util/print';
import AppConfig from '@/Components/AppConfig';


interface Props {
    event: EventResponse
    hideModal: () => void;
    onButtonClick: () => void;
    onInputClick: (file) => void;
    isActive: boolean;
}

export default function ModalAppConfig({
    event,
    hideModal,
    onButtonClick,
    onInputClick,
    isActive,
}: Props) {
    
    return (
    <>
        <TModal
            active={isActive}
            width='80vw'
            height='60vh'
            hideModal={() => undefined}
            footer={
                <>
                </>
            }
        >
            <ModalContainer>
                <a onClick={() => hideModal()}>
                    <CloseButton />
                </a>
                <AppConfig event={event} />
            </ModalContainer>
        </TModal>
    </>
    );
}
