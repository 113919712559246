import styled from 'styled-components';

interface ButtonProps {
    backgroundColor: string;
}

export const ModalContainer = styled.div``;

export const Title = styled.h2`
    padding-top: 0px;
    text-align: center;

    font-size: ${({ theme }) => theme.fontSize['2xl']};
`;

export const ModalFooterContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
`;

export const AlredyCheckedInContainer = styled(ModalFooterContainer)`
    justify-content: center;
`;


export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: bold;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-height: 80vh;
    margin-top: 20px;
`;

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ImportExcel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        color: ${({ theme }) => theme.colors.white};
        font-size: ${({ theme }) => theme.fontSize.xs};
        cursor: pointer;

        align-items: center;
        height: 100%;
    }
`;

export const QRCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
    gap: 2vh;
    max-height: 250px;
`