import { Background } from '@/Components/Background';
import ModalLoading from '@/Components/ModalLoading';
import { Header } from '@/Components/Header';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiService from '@/Services/api/ApiService';
import { EventResponse, IMetrics } from '@/Services/api/models/eventModel';
import { EventTitle } from '@/Pages/Participants/Consultar/styled';

import { PieChart, Pie, Tooltip, Cell, Legend, Area, AreaChart, XAxis, YAxis, CartesianGrid, Bar, BarChart } from 'recharts';
import { Chart, ChartsContainer, MetricsContainer, ReportBackground, Row, Title } from './styled';
import { ParticipantCategories } from '@/Services/api/models/participantCategoryModel';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Tab, Tabs } from 'react-bootstrap';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';

export function EventReports() {
    const [isLoading, setIsLoading] = useState(false);
    const [eventInfo, setEventInfo] = useState<EventResponse>();
    const [eventMetrics, setEventMetrics] = useState<IMetrics>();
    const [categories, setCategories] = useState<Array<ParticipantCategories>>()
    const [update, setUpdate] = useState<number>(0)

    const { eventId } = useParams();


    const getEventInfo = async () => {
        try {
            setIsLoading(true);
            if (!eventId) {
                throw new Error('Evento não informado na URL');
            }
            const eventInfo = await ApiService.getEventById(+eventId);
            const eventMetrics = await ApiService.getEventMetrics(+eventId);
            setEventInfo(eventInfo);
            setEventMetrics(eventMetrics);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getParticipantCategories = async () => {
        setIsLoading(true)
        try {
            const categories = await ApiService.getParticipantCategories()
            setCategories(categories)
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        getEventInfo();
        getParticipantCategories()
    }, [update]);


    const dataCheckIn = [
        { name: 'Realizaram Check-in', value: eventMetrics?.participantsCheckIn },
        { name: 'Pendentes', value: eventMetrics?.participantsToArrive },
    ];

    const dataCameFrom = [
        { name: 'Admin', value: eventMetrics?.participantsFromAdmin },
        { name: 'Excel', value: eventMetrics?.participantsFromExcel },
    ];

    const dataCheckInByCategory = eventMetrics
        ? categories?.map((category) => {
            return {name: category.categoryName, 'Pendentes': eventMetrics?.participantsByCategory?.[category.categoryName] - eventMetrics?.participantsCheckInByCategory?.[category.categoryName] || 0, 'Realizaram Check-in': eventMetrics?.participantsCheckInByCategory?.[category.categoryName]}
         })
         : []

    const dataCheckInByEventCategory =  eventMetrics 
        ? Object.keys(eventMetrics.participantsByEventCategory)?.map((category) => {
            return {name: (category === "1"? "Sem categoria" : category), 'Pendentes': eventMetrics?.participantsByEventCategory?.[category] - eventMetrics?.participantsCheckInByEventCategory?.[category] || 0, 'Realizaram Check-in': eventMetrics?.participantsCheckInByEventCategory?.[category]}
        })
        : []

    console.log(dataCheckInByEventCategory)

    const dataParticipantsByCategory = eventMetrics
        ? Object.keys(eventMetrics.participantsByCategory)?.map((category) => {
           return {name: category, value: eventMetrics?.participantsByCategory?.[category] || 0}
        })
        : []

    const cekData = eventMetrics?.kitQuantityLimit
        ? [
            {name: 'Kits disponíveis', value: eventMetrics?.kitQuantityLimit - eventMetrics.participantsCekWithdraw},
            {name: 'Kits retirados', value: eventMetrics?.participantsCekWithdraw},
        ]   
        : []

    const COLORS = [
        '#3366cc',
        '#dc3912',
        '#ff9900',
        '#109618',
        '#990099',
        '#0099c6',
        '#dd4477',
        '#66aa00',
        '#b82e2e',
        '#316395',
        '#3366cc',
        '#994499',
        '#22aa99',
        '#aaaa11',
        '#6633cc',
        '#e67300',
        '#8b0707',
        '#651067',
        '#329262',
        '#5574a6',
        '#3b3eac',
        '#b77322',
        '#16d620',
        '#b91383',
        '#f4359e',
        '#9c5935',
        '#a9c413',
        '#2a778d',
        '#668d1c',
        '#bea413',
        '#0c5922',
        '#743411',
    ];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill='white'
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline='central'
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        /*<Background>
            <ModalLoading isActive={isLoading} />
            <Header pageTitle='Relatório de acompanhamento' />
            <Row>
                <EventTitle>
                    {' '}
                    <span> Evento: </span> {eventInfo?.description} ({eventInfo?.id}){' '}
                </EventTitle>
                <div onClick={() => setUpdate(update => update+1)}>
                    <RefreshIcon />
                </div>
            </Row>*/
            <ReportBackground>
                <Title>Relatórios do Evento</Title>
                <Tabs
                    defaultActiveKey="charts"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >            
                    <Tab eventKey="charts" title="Gráficos">
                        <ChartsContainer>
                            <Chart>
                                <Title> Distribuição de check-in </Title>
                                <AreaChart width={900} height={300} data={eventMetrics?.participantsByTimeSpan}>
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <Tooltip />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Area type="monotone" dataKey="checkIn" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                </AreaChart>
                            </Chart>
                            <Chart>
                                <Title> Check-in </Title>
                                <PieChart width={300} height={300}>
                                    <Pie
                                        data={dataCheckIn}
                                        cx='50%'
                                        cy='50%'
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={80}
                                        fill='#8884d8'
                                        dataKey='value'
                                    >
                                        {dataCheckIn.map((_, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={COLORS[index % COLORS.length]}
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='vertical' verticalAlign='bottom' />
                                </PieChart>
                            </Chart>
                            <Chart>
                                <Title> Origem do Cadastro </Title>
                                <PieChart width={300} height={300}>
                                    <Pie
                                        data={dataCameFrom}
                                        cx='50%'
                                        cy='50%'
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={80}
                                        fill='#8884d8'
                                        dataKey='value'
                                    >
                                        {dataCameFrom.map((_, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    COLORS.slice(dataCheckIn.length)[index % COLORS.length]
                                                }
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='vertical' verticalAlign='bottom' />
                                </PieChart>
                            </Chart>
                            <Chart>
                                <Title> Participantes por categoria </Title>
                                <PieChart width={300} height={300}>
                                    <Pie
                                        data={dataParticipantsByCategory}
                                        cx='50%'
                                        cy='50%'
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={80}
                                        fill='#8884d8'
                                        dataKey='value'
                                    >
                                        {dataParticipantsByCategory.map((_, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={COLORS[index % COLORS.length]} 
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='horizontal' verticalAlign='bottom' />
                                </PieChart>
                            </Chart>
                            <Chart>
                                <Title> Check-in por categoria </Title>
                                <BarChart width={600} height={300} data={dataCheckInByCategory} >
                                    <CartesianGrid vertical={false} />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Bar dataKey="Realizaram Check-in" stackId="a" fill="#8884d8" />
                                    <Bar dataKey="Pendentes" stackId="a" fill="#82ca9d" />
                                    <Tooltip cursor={{fill: 'transparent'}} />
                                </BarChart>
                            </Chart>
                            <Chart>
                                <Title> Check-in por categoria do evento </Title>
                                <BarChart width={600} height={300} data={dataCheckInByEventCategory} >
                                    <CartesianGrid vertical={false} />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Bar dataKey="Realizaram Check-in" stackId="a" fill="#8884d8" />
                                    <Bar dataKey="Pendentes" stackId="a" fill="#82ca9d" />
                                    <Tooltip cursor={{fill: 'transparent'}} />
                                </BarChart>
                            </Chart>
                            {cekData.length > 0?
                                <Chart>
                                    <Title> CEK </Title>
                                    <PieChart width={300} height={300}>
                                        <Pie
                                            data={cekData}
                                            cx='50%'
                                            cy='50%'
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                            outerRadius={80}
                                            fill='#8884d8'
                                            dataKey='value'
                                        >
                                            {cekData.map((_, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS[index % COLORS.length]} 
                                                />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend layout='horizontal' verticalAlign='bottom' />
                                    </PieChart>
                                </Chart>
                                :<></>
                            }
                        </ChartsContainer>
                    </Tab>
                    <Tab eventKey="metrics" title="Métricas">
                        <MetricsContainer>
                            <Title>Participantes</Title>
                            <p>{`Total de Participantes: ${eventMetrics?.participantsTotal}`}</p>
                            <p>{`Participantes importados do Excel: ${eventMetrics?.participantsFromExcel}`}</p>
                            <Title>Categorias</Title>
                            {eventMetrics?.participantsByCategory && Object.keys(eventMetrics?.participantsByCategory)?.map((category) =>
                                <p>{`${category}: ${eventMetrics?.participantsByCategory?.[category]}`}</p>
                            )}
                            <Title>Check-in</Title>
                            <p>{`Total de check-ins: ${eventMetrics?.participantsCheckIn}`}</p>
                            <p>{`Tempo médio de check-in: ${Number(eventMetrics?.averageCheckInDuration) /1000} segundos`}</p>
                        </MetricsContainer>
                    </Tab>    
                </Tabs>
            </ReportBackground>
        //</Background>
    );
}
