import styled from 'styled-components';

export const GetEventosContainer = styled.div``;

export const WhiteBoxContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};

    min-height: 80vh;
    
    padding: 20px;
    margin: 20px;

    table {
        min-height: 150px;
    }

    .header {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 41px;
            height: 22px;
            font-size: ${({ theme }) => theme.fontSize.xs};
        }
    }
    .body {
        display: flex;
        flex-direction: row;
    }

    .tabela {
        overflow: auto;
        padding-bottom: 50px;
        min-height: 70vh;
    }

    th {
        font-weight: 700;
    }

    td,
    th {
        font-size: ${({ theme }) => theme.fontSize.form};
    }

    .dropdown {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            height: 22px;
            width: 50px;
            font-size: ${({ theme }) => theme.fontSize.xs};

            :hover {
                color: white;
            }
        }

        .dropdown-item {
            font-size: ${({ theme }) => theme.fontSize.xs};
            color: ${({ theme }) => theme.colors['gray-700']};
        }
    }
`;

export const StatusButton = styled.button`
    font-size: ${({ theme }) => theme.fontSize.us};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;

    text-transform: uppercase;
    border-radius: 4px;

    border: none;
    padding: 3px 8px;

    .active {
        background-color: ${({ theme }) => theme.colors.steelblue};
    }

    .inative {
        background-color: ${({ theme }) => theme.colors['pink-500']};
    }

`;

export const SmallerColumn = styled.td`
    width: 10%;

    .active {
        background-color: ${({ theme }) => theme.colors.steelblue};
    }

    .inative {
        background-color: ${({ theme }) => theme.colors['pink-500']};
    }
`;

export const CapitalizedRow = styled.td`
    text-transform: capitalize;
`;
