import styled from 'styled-components';

export const WhiteBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

export const CekDashContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};

    min-height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 705px) {
        flex-direction: column;
        .addField {
            min-width: 50vw;
        }
    }

    .header {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 41px;
            height: 22px;
            font-size: ${({ theme }) => theme.fontSize.xs};
        }
    }
    .body {
        display: flex;
        flex-direction: row;
    }

    .tabela {
        overflow: auto;
        padding-bottom: 50px;
        min-height: 70vh;
    }

    th {
        font-weight: 700;
    }

    td,
    th {
        font-size: ${({ theme }) => theme.fontSize.form};
    }

    .dropdown {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            height: 22px;
            width: 50px;
            font-size: ${({ theme }) => theme.fontSize.xs};

            :hover {
                color: white;
            }
        }

        .dropdown-item {
            font-size: ${({ theme }) => theme.fontSize.xs};
            color: ${({ theme }) => theme.colors['gray-700']};
        }
    }

    .hiddenInput {
        display: None
    }

`;

export const LeftSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 60vw;
    @media screen and (max-width: 705px) {
        width: 80vw;
    }
`

export const RightSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40vw;
    margin-top: 5vh;
    @media screen and (max-width: 705px) {
        width: 80vw;
    }
`

export const StatusButton = styled.button`
    font-size: ${({ theme }) => theme.fontSize.us};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;

    text-transform: uppercase;
    border-radius: 4px;

    border: none;
    padding: 3px 8px;
`;

export const SmallerColumn = styled.td`
    width: 10%;

    .active {
        background-color: ${({ theme }) => theme.colors.steelblue};
    }

    .inative {
        background-color: ${({ theme }) => theme.colors['pink-500']};
    }
`;

export const CapitalizedRow = styled.td`
    text-transform: capitalize;
`;

export const AddContainer = styled.div`
    display: flex;
    flex-direction: column;
    
`;

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    
`;

export const FilterFields = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 40vw;
`;

export const ChartsContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    flex-wrap: wrap;
    gap: 25px;
`;
export const Chart = styled.div`
    text-align: center;
`;

export const DynamicMenuContainer = styled.div`
    display: flex;
    margin-top: 5vh;
    margin-bottom: 2vh;
    height: 6vh;
`
export const Title = styled.h1`
    color: ${({ theme }) => theme.colors['gray-700']};
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    font-weight: 700;
    padding: 20px 0px 30px 0px;
    margin: 0;
`
